import { fetchBaseQuery, createApi } from "@reduxjs/toolkit/query/react";

export const apiReservationSlice = createApi({
  reducerPath: "reservationData",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_PATH,
  }),
  endpoints: (builder) => ({
    getReservationOnId: builder.query({
      query: (property_id) => `/getroomReservationById/property_id/${property_id} `,
    }),
    getReseravtionData : builder.query({
      query :()=> `/getroomReservation`
    }),
    addReservationData : builder.mutation({
      query : ({property_id , values}) =>({
        url : `/property/${property_id}/reservation`,
        method : "POST",
        body : values,
        headers : {},
      })
    }),
    editReservationData: builder.mutation({
      query: ({ PropertiesId, values, reservation_id }) => ({
        url: `/property/${PropertiesId}/updateRoomReservation/${reservation_id}`,
        method: "PUT",
        body: values,
        headers: {},
      }),
    }),
    deleteReservation: builder.mutation({
      query: ({ reservation_id }) => ({
        url: `/delete-reservation-data/reservation_id/${reservation_id}`,
        method: "DELETE",
        header: {},
      }),
    }),
  }),
});
export const {
  useGetReservationOnIdQuery,
  useGetReseravtionDataQuery,
  useAddReservationDataMutation,
  useEditReservationDataMutation,
  useDeleteReservationMutation,
} = apiReservationSlice;
