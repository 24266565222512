import { fetchBaseQuery, createApi } from "@reduxjs/toolkit/query/react";

export const apiCleaningSlice = createApi({
  reducerPath: "cleaningGetApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_PATH,
  }),
  endpoints: (builder) => ({
    getCleaningById: builder.query({
      query: (property_id) => `/getcleaningdata/property_id/${property_id}`,
    }),
    getCleaningData: builder.query({
      query: () => `/getcleaningdata`,
    }),

    addCleaningData: builder.mutation({
      query: ({ cleaningData, property_id }) => ({
        url: `/cleaningdata/${property_id}`,
        method: "POST",
        body: cleaningData,
        header: {},
      }),
    }),
    updateCleaningData: builder.mutation({
      query: ({ cleaningData, FormData }) => ({
        url: `updateCleaningData/${cleaningData}`,
        method: "PUT",
        body: FormData,
        header: {},
      }),
    }),
    deleteCleaningModal: builder.mutation({
      query: ({ cleaning_id }) => ({
        url: `/deletecleaning/cleaningid/${cleaning_id}`,
        method: "DELETE",
        header: {},
      }),
    }),
  }),
});
export const {
  useGetCleaningByIdQuery,
  useGetCleaningDataQuery,
  useAddCleaningDataMutation,
  useUpdateCleaningDataMutation,
  useDeleteCleaningModalMutation,
} = apiCleaningSlice;
