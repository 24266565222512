import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const apipropertyslice = createApi({
  reducerPath: "PropertyApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_PATH,
  }),
  endpoints: (builder) => ({
    GetAllProperties: builder.query({
      query: () => `/getproperties`,
    }),
    addProperty : builder.mutation({
      query : ({formData}) =>({
        url : `/addProperties`,
        method :"POST",
        body : formData,
        header : {
          "Content-Type": "multipart/form-data",
        }
      })
    }),
    updateProperty: builder.mutation({
      query: ({ selectedProperty, formData }) => ({
        url: `/updateProperties/${selectedProperty}`,
        method: "PUT",
        body: formData,
        headers: {},
      }),
    }),
    deleteProperty : builder.mutation({
      query : ({property_id}) => ({
        url : `/deleteproperties/${property_id}`,
        method : "Delete",
        header : {}

      })
    })
  }),
});

export const { useGetAllPropertiesQuery, useAddPropertyMutation ,
               useUpdatePropertyMutation , useDeletePropertyMutation
} = apipropertyslice;
