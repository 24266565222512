import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from '@reduxjs/toolkit/query'
import { apiCleaningSlice } from "slice/apiCleaningSlice";
import { apipropertyslice } from "slice/apipropertyslice";
import { apiReservationSlice } from "slice/apiReservationSlice";
import { apiExpenseSilce } from "slice/apiExpenseSlice";
import {apiDropDownSlice} from 'slice/apiDropDownSlice'
import { apiManageBookingPaymentMethod } from "slice/ManageBookingPaymentMethod";
export const store = configureStore({
  reducer: {
    [apipropertyslice.reducerPath]: apipropertyslice.reducer,
    [apiReservationSlice.reducerPath] : apiReservationSlice.reducer,
    [apiCleaningSlice.reducerPath] : apiCleaningSlice.reducer,
    [apiExpenseSilce.reducerPath] : apiExpenseSilce.reducer,
    [apiDropDownSlice.reducerPath] : apiDropDownSlice.reducer,
    [apiManageBookingPaymentMethod.reducerPath] : apiManageBookingPaymentMethod.reducer
  },
  middleware: (getDefaultMiddleware) => 
    getDefaultMiddleware()
        .concat(apipropertyslice.middleware)
        .concat(apiReservationSlice.middleware)
        .concat(apiCleaningSlice.middleware)
        .concat(apiExpenseSilce.middleware)
        .concat(apiDropDownSlice.middleware )
        .concat(apiManageBookingPaymentMethod.middleware)
      
},); 

setupListeners(store.dispatch);


