import React, { useEffect, useState, useMemo } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { IconButton, TextField, InputAdornment, Tooltip } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Sidenav from "examples/Sidenav";
import routes from "routes";
import { debounce } from "lodash";
import axios from "axios";
import DataTable from "examples/Tables/DataTable";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useLocation } from "react-router-dom";
import ModelData from "components/ModelAdd/ModelData";
import CircularProgress from "@mui/material/CircularProgress";
import MDButton from "components/MDButton";
import ReservationModel from "components/ModelAdd/ReservationModel";
import CleaningModel from "components/Cleaning/CleaningModel";
import Autocomplete from "@mui/material/Autocomplete";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { useGetAllPropertiesQuery } from "slice/apipropertyslice";
import { useDeletePropertyMutation } from "slice/apipropertyslice";
import { CloseFullscreen } from "@mui/icons-material";

const ListProperties = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [dropDownOptions, setDropDownOptions] = useState([]);
  const [properties, setProperties] = useState([]);
  const [filteredProperties, setFilteredProperties] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [loading, setLoading] = useState(true);
  const [Isreservation, setIsreservation] = useState(false);
  const [selectedRoomId, setSelectedRoomId] = useState(null);
  const [IsExpensesModel, setIsExpensesModel] = useState(false);
  const [cleaningData, setCleaningData] = useState(null);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [dropDownProperty, setDropDownProperty] = useState("");
  const [isDataFetched, setIsDataFetched] = useState(false);
  const { data, error, isLoading, refetch: refreshgetproperty } = useGetAllPropertiesQuery();
  const [deleteProperty, { loading: deleteLoading, error: deleteError }] = useDeletePropertyMutation();

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoading) {
      setLoading(true)
      setProperties([]);
      setIsDataFetched(false);
    } else if (data?.data) {
      setProperties(data.data);
      setIsDataFetched(true);
    }
    setLoading(false)
  }, [isLoading, data]);

  const handleresesrvation = async (property_id) => {
    navigate(`/room-reservation/${property_id}`);
  };

  const handleCleaningService = async (property_id) => {
    navigate(`/cleaning/${property_id}`);
  };

  const handleExpense = async (property_id) => {
    navigate(`/expense/${property_id}`);
  };

  useEffect(() => {
    const filteredData = properties.filter((property) =>
      property.property_name.toLowerCase().includes(dropDownProperty.toLowerCase())
    );
    setFilteredProperties(filteredData);
  }, [dropDownProperty, properties]);

  useEffect(() => {
    const options = properties.map((property) => property.property_name); // Extract property names
    setDropDownOptions(options); // Set dropdown options
  }, [properties]);


  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 300);

    return () => clearTimeout(handler);
  }, [searchTerm]);

  const filteredData = useMemo(() => {
    if (!properties) return [];

    return properties.filter((property) => {
      const matchesDropDown = dropDownProperty
        ? property.property_name?.toLowerCase().includes(dropDownProperty.toLowerCase()) ||
        property.ward?.toLowerCase().includes(dropDownProperty.toLowerCase()) ||
        property.city?.toLowerCase().includes(dropDownProperty.toLowerCase()) ||
        property.zip?.toString().includes(dropDownProperty.toLowerCase()) ||
        property.street?.toLowerCase().includes(dropDownProperty.toLowerCase())
        : true;

      const matchesSearchTerm = debouncedSearchTerm
        ? property.property_name?.toLowerCase().includes(debouncedSearchTerm.toLowerCase()) ||
        property.ward?.toLowerCase().includes(debouncedSearchTerm.toLowerCase()) ||
        property.city?.toLowerCase().includes(debouncedSearchTerm.toLowerCase()) ||
        property.zip?.toString().includes(debouncedSearchTerm.toLowerCase()) ||
        property.street?.toLowerCase().includes(debouncedSearchTerm.toLowerCase())
        : true;

      return matchesDropDown && matchesSearchTerm;
    });
  }, [dropDownProperty, properties, debouncedSearchTerm])

  useEffect(() => {
    setFilteredProperties(filteredData)
  }, [filteredData])



  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleEdit = (property) => {
    setSelectedProperty(property);
    setModalVisible(true);
  };

  const handleDelete = (property) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this Property record?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Delete!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteProperty({
          property_id: property.property_id,
        }).then((res) => {
          if (res.data.status === 200) {
            refreshgetproperty()
            toast.success(res.data.message);
          }
        })
          .catch((error) => {
            console.log(error);
            toast.error("An error occurred while deleting the record.");
          });
      }
    });
  };

  const closeModal = () => {
    setModalVisible(false);
    setSelectedProperty(null);
  };
  const handleReservation = (roomId) => {
    setIsreservation(true);
    setSelectedRoomId(roomId);
  };
  const handleExpensess = (roomId) => {
    setIsExpensesModel(true);
    setCleaningData(roomId);
  };
  const handleClose = () => {
    setIsreservation(false);
    setSelectedRoomId(null);
  };
  const shouldShowModal = location.pathname !== "/properties/list-properties";
  const columns = [
    {
      Header: () => (
        <MDTypography
          variant="h6"
          ml={3}
          style={{ fontWeight: "bold", color: "#000" }}
          sx={{ fontSize: { xs: '12px', sm: '16px' } }}
        >
          Properties
        </MDTypography>
      ),
      accessor: "propertyname",
    },
    {
      Header: () => (
        <MDTypography
          variant="h6"
          ml={2}
          style={{ fontWeight: "bold", color: "#000" }}
          sx={{ fontSize: { xs: '12px', sm: '16px' } }}
        >
          Address
        </MDTypography>
      ),
      accessor: "city",
    },
    {
      Header: () => (
        <MDTypography
          variant="h6"
          ml={3}
          width={170}
          style={{ fontWeight: "bold", color: "#000" }}
          sx={{ fontSize: { xs: '12px', sm: '16px' } }}
        >
          Property Access Guide
        </MDTypography>
      ),
      accessor: "files",
    },
    ...(shouldShowModal
      ? [
        {
          Header: () => (
            <MDTypography
              variant="h6"
              align="center"
              ml={3}
              style={{
                // fontSize: "16px",
                fontWeight: "bold",
                color: "#000",
                alignItems: "center",
              }}
              sx={{ fontSize: { xs: '12px', sm: '16px' } }}
            >
              Actions
            </MDTypography>
          ),
          accessor: "actions",
        },
      ]
      : []),
  ];
  const rows = filteredProperties?.map((property) => ({
    propertyname: (
      <MDTypography
        variant="caption"
        color="text"
        sx={{
          paddingTop: 0,
          paddingBottom: 0,
          fontSize: { xs: "12px", sm: "14px", md: "16px" }
        }}
        fontWeight="medium"
      // style={{ fontSize: "14px", }}
      >
        {property.property_name || ""}
      </MDTypography>
    ),
    city: (
      <MDTypography
        variant="caption"
        color="text"
        fontWeight="medium"
        sx={{ fontSize: { xs: "12px", sm: "14px", md: "16px" } }}
      // style={{ fontSize: "14px" }}
      >
        {`${property.zip || ""}${property.zip ? ", " : ""}${property.city || ""}${property.city ? ", " : ""
          }${property.ward || ""}${property.ward ? ", " : ""}${property.street || ""}` || ""}
      </MDTypography>
    ),
    files: property.pdf_file ? (
      <a href={property.pdf_file || ""} target="_blank" rel="noopener noreferrer">
        <MDTypography
          variant="caption"
          color="blue"
          fontWeight="medium"
          sx={{ fontSize: { xs: "12px", sm: "14px", md: "16px" } }}
        >
          Show file
        </MDTypography>
      </a>
    ) : (
      ""
    ),

    actions: (
      <MDTypography
        variant="caption"
        color="text"
        display="flex"
        fontWeight="medium"
        className="action-fixed"
        gap={1}
        style={{ fontSize: "14px", right: "20px" }}
      >
        <div>
          <MDTypography
            variant="caption"
            color="text"
            align="center"
            fontWeight="medium"
            style={{ fontSize: "14px" }}
          >
            <div>
              <MDTypography
                ml={6}
                mr={1}
                mt={1}
                color="text"
                fontWeight="medium"
                style={{
                  color: "#1e90ff", cursor: "pointer",

                }}
                sx={{ fontSize: { xs: "12px", sm: "14px", md: "16px" } }}
                onClick={() => handleReservation(property)}
              >
                Add reservation
              </MDTypography>
            </div>
          </MDTypography>
        </div>

        <div>
          <MDTypography
            variant="caption"
            color="text"
            fontWeight="medium"
            mr={11}

            style={{ fontSize: "14px" }}
          >
            <div>
              <MDTypography
                mt={1}
                color="text"
                fontWeight="medium"
                style={{
                  color: "#1e90ff", cursor: "pointer"
                }}
                sx={{ fontSize: { xs: "12px", sm: "14px", md: "16px" } }}

                onClick={() => handleExpensess(property)}
              >
                Add cleaning
              </MDTypography>
            </div>
          </MDTypography>
        </div>
        <div>
          <Tooltip title=" Show reservations">
            <MDTypography
              ml={0.8}
              mt={1}
              color="text"
              fontWeight="medium"
              sx={{ color: "#1e90ff", cursor: "pointer", fontSize: { xs: "12px", sm: "14px", md: "16px" } }}
              onClick={() => handleresesrvation(property.property_id)}
            >
              Reservations
            </MDTypography>
          </Tooltip>
        </div>
        <div>
          <Tooltip title="Show cleanings">
            <MDTypography
              ml={1.3}
              mt={1}
              color="text"
              fontWeight="medium"
              sx={{ color: "#1e90ff", cursor: "pointer", fontSize: { xs: "12px", sm: "14px", md: "16px" } }}
              onClick={() => handleCleaningService(property.property_id)}
            >
              Cleanings
            </MDTypography>
          </Tooltip>
        </div>
        <div>
          <Tooltip title="Show expenses">
            <MDTypography
              ml={1.3}
              mt={1}
              color="text"
              fontWeight="medium"
              sx={{ color: "#1e90ff", cursor: "pointer", fontSize: { xs: "12px", sm: "14px", md: "16px" } }}
              onClick={() => handleExpense(property.property_id)}
            >
              Expenses
            </MDTypography>
          </Tooltip>
        </div>
        <div>
          <Tooltip title="Edit">
            <MDTypography
              ml={2}
              onClick={() => handleEdit(property)}
              sx={{ color: "#1e90ff", cursor: "pointer", fontSize: "20px" }}
            >
              <EditIcon />
            </MDTypography>
          </Tooltip>
        </div>
        <div>
          <Tooltip title="Delete">
            <MDTypography
              ml={1.5}
              sx={{ color: "red", padding: "2px", cursor: "pointer", fontSize: "20px" }}
              onClick={() => handleDelete(property)}
            >
              <DeleteIcon />
            </MDTypography>
          </Tooltip>
        </div>
      </MDTypography>
    ),
  }));

  return (
    <>
      <DashboardLayout>
        <Sidenav color="primary" brandName="RentRoll" routes={routes} />
        <MDBox sx={{ pt: { xs: 6, sm: 5 } }} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  sx={{ backgroundColor: "#1e90ff", display: { sm: 'flex' }, justifyContent: { xs: 'center', sm: 'space-between' } }}
                  borderRadius="lg"
                  coloredShadow="info"
                  // display="flex"
                  // justifyContent="space-between"
                  alignItems="center"
                >
                  <MDTypography variant="h5" color="white" sx={{ fontSize: { xs: '15px', sm: '25px' }, alignItems: { xs: 'center' } }}>
                    Properties Table
                  </MDTypography>
                  <MDBox>
                    <MDBox display="flex" justifyContent="flex-end" alignItems="center" sx={{ justifyContent: { xs: 'center' }, alignItems: { xs: 'center' } }} >
                      <MDBox mb={2} mr={2} mt={1.7} width={220}
                        sx={{
                          width: { xs: "120px", sm: "220px" },
                          marginRight: { xs: "8px" },
                          // display: { xs: "none", sm: "block" }
                        }}
                      >
                        <Autocomplete
                          options={dropDownOptions} // Use dropdown options
                          getOptionLabel={(option) => option} // Options are strings (property names)
                          onChange={(event, value) => setDropDownProperty(value || "")} // Update selected property
                          value={dropDownProperty}
                          style={{ border: "none" }}
                          filterOptions={(options, state) =>
                            options.filter((option) =>
                              option.toLowerCase().includes(state.inputValue.toLowerCase())
                            )
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Search Properties"
                              style={{ border: "none" }}
                              sx={{
                                background: "white",
                                color: "black",
                                border: "none",
                                borderRadius: "8px ",
                                margin: "0px",
                                ".MuiOutlinedInput-root": {
                                  padding: "2px", // Set padding to 2px
                                },
                                ".MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                                {
                                  border: "none", // Remove the border on focus
                                },
                                ".MuiOutlinedInput-notchedOutline": {
                                  border: "none", // Remove the border for normal state
                                },
                              }}
                            />
                          )}
                        />
                      </MDBox>

                      {shouldShowModal && (
                        <MDButton
                          style={{
                            color: "black",
                            border: "1px solid white",
                            background: "#white",
                            marginRight: "16px",
                            padding: "3px 10px",
                          }}
                          sx={{
                            padding: { xs: "3px 0px" },
                            fontSize: { xs: '10px' },

                          }}
                          onClick={() => setModalVisible(true)}

                        >
                          Add Property
                        </MDButton>
                      )}

                      <TextField
                        placeholder="Search "
                        size="small"
                        sx={{
                          "& .MuiInputBase-root": {
                            backgroundColor: "#ffffff",
                            color: "#000000",
                            border: "none",
                            padding: "3px 10px",
                          },
                          "& .MuiInputLabel-root": {
                            display: "none",
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            border: "none",
                          },
                          "& .MuiInputBase-input": {
                            padding: "8px",
                          },
                          display: { xs: "none", sm: "block" },
                        }}
                        inputProps={{ "aria-label": "Search" }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                        onChange={handleSearch}
                        value={searchTerm}
                      />
                    </MDBox>
                  </MDBox>
                </MDBox>

                {isLoading || !isDataFetched ? (
                  <MDTypography
                    variant="caption"
                    color="text"
                    fontWeight="medium"
                    align="center"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100vh",
                    }}
                  >
                    <CircularProgress disableShrink sx={{ color: "#1e90FF" }} />
                  </MDTypography>
                ) : (
                  <MDBox  >
                    {filteredProperties && filteredProperties?.length > 0 ? (
                        <DataTable
                           tableId="propertyTable"
                        table={{ columns, rows }}
                        isSorted={false}
                        showTotalEntries={false}
                        entriesPerPage={{ defaultValue: 15, entries: [10, 20, 30, 40, 50, 60] }}
                        noEndBorder
                      />
                    ) : (
                      <MDTypography variant="h6" align="center" m={2} sx={{ color: "red" }}>
                        No properties found.
                      </MDTypography>
                    )}
                  </MDBox>
                )}
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        {shouldShowModal && (
          <ModelData
            isVisible={modalVisible}
            addProperty={refreshgetproperty}
            close={closeModal}
            selectedProperty={selectedProperty}
          />
        )}
        <ReservationModel isVisible={Isreservation} Close={handleClose} roomId={selectedRoomId} />
        <CleaningModel
          isVisible={IsExpensesModel}
          Close={() => {
            setIsExpensesModel(false);
          }}
          roomId={cleaningData}
        />
      </DashboardLayout>
    </>
  );
};

export default ListProperties;
