import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import axios from "axios";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Sidenav from "examples/Sidenav";
import routes from "routes";
import { Box, Button, IconButton, TextField, InputAdornment } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import SearchIcon from "@mui/icons-material/Search";
import ExpensesModel from "components/ModelAdd/ExpensesModel";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { toast } from "react-toastify";
import { useGetExpenseOnIdQuery , useUpdatePropertyMutation } from "slice/apiExpenseSlice";
import Swal from "sweetalert2";
import { useParams } from "react-router";
import { useNavigate } from "react-router";

const Index = () => {
  const [IsExpenses, setExpenses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [IsExpensesModel, setIsExpensesModel] = useState(false);
  const [Selectedexpense, setSelectedexpense] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredExpenses, setFilteredExpenses] = useState([]);
  const [expenseById, getExpenseById] = useState([]);
  const { property_id } = useParams();
  const {data , refetch : refreshOnId} = useGetExpenseOnIdQuery(property_id);
    
  const navigate = useNavigate();

   useEffect(()=>{
    setLoading(true)
      if(data?.data){
        getExpenseById(data.data)
      }
      setLoading(false)
   },[data])

  // const handleExpenseById = async () => {
  //   try {
  //     const response = await axios.get(
  //       `${process.env.REACT_APP_API_PATH}/getexpenseList/property_id/${property_id}`,
  //       {}
  //     );
  //     if (response.data.success && Array.isArray(response.data.data)) {
  //       getExpenseById(response.data.data);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  useEffect(() => {
    refreshOnId();
  }, [property_id]);

  // const fetchExpenses = async () => {
  //   try {
  //     setLoading(true);
  //     const response = await axios.get(`${process.env.REACT_APP_API_PATH}/getexpenseList`);
  //     if (response.data.success && Array.isArray(response.data.data)) {
  //       setExpenses(response.data.data);
  //     } else {
  //       console.error("Failed to fetch expense data.");
  //     }
  //   } catch (error) {
  //     console.error("An error occurred while fetching expenses data:", error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };
  const handleBack = (e)=>{
    e.preventDefault()
    navigate(-1)
  }

  useEffect(() => {
    const filteredData = expenseById.filter((expense) => {
      const propertyName = expense.property_name.toLowerCase().includes(searchTerm.toLowerCase());
      const title = expense.title.toLowerCase().includes(searchTerm.toLowerCase());
      const amount = expense.expense_amount
        ?.toString()
        .toLowerCase()
        .includes(searchTerm.toLowerCase());
      const description = expense.description.toLowerCase().includes(searchTerm.toLowerCase());
      return propertyName || title || amount || description;
    });
    getExpenseById(filteredData);
  }, [searchTerm, IsExpenses]);

  const handleDelete = (expense) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this expense record?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Delete!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(
            `${process.env.REACT_APP_API_PATH}/expense/delete-exepense/expense-id/${expense.expense_id}`
          )
          .then((res) => {
            if (res.data.status === 200) {
              refreshOnId();
              toast.success(res.data.message);
            }
          })
          .catch((err) => {
            console.log(err);
            toast.error("An error occurred while deleting the record.");
          });
      }
    });
  };

  // useEffect(() => {
  //   fetchExpenses();
  // }, []);
  const handleEdit = (expense) => {
    setIsExpensesModel(true);
    setSelectedexpense(expense);
    refreshOnId();
  };
  const columns = [
    {
      Header: () => (
        <MDTypography
          variant="h6"
          ml={3}
          style={{ fontSize: "16px", fontWeight: "bold", color: "#000" }}
        >
          Properties
        </MDTypography>
      ),
      accessor: "Apartment",
    },
    {
      Header: () => (
        <MDTypography
          variant="h6"
          ml={3}
          style={{ fontSize: "16px", fontWeight: "bold", color: "#000" }}
        >
          Expense title
        </MDTypography>
      ),
      accessor: "Expenses",
    },
    {
      Header: () => (
        <MDTypography
          variant="h6"
          ml={3}
          style={{ fontSize: "16px", fontWeight: "bold", color: "#000" }}
        >
          Amount
        </MDTypography>
      ),
      accessor: "amount",
    },
    {
      Header: () => (
        <MDTypography
          variant="h6"
          ml={3}
          style={{ fontSize: "16px", fontWeight: "bold", color: "#000" }}
        >
          Description
        </MDTypography>
      ),
      accessor: "Description",
    },
    {
      Header: () => (
        <MDTypography
          variant="h6"
          ml={3}
          style={{ fontSize: "16px", fontWeight: "bold", color: "#000" }}
        >
          Start date
        </MDTypography>
      ),
      accessor: "Startdate",
    },
    {
      Header: () => (
        <MDTypography
          variant="h6"
          ml={3}
          style={{ fontSize: "16px", fontWeight: "bold", color: "#000" }}
        >
          End date
        </MDTypography>
      ),
      accessor: "end_date",
    },
    {
      Header: () => (
        <MDTypography
          variant="h6"
          ml={3}
          style={{ fontSize: "16px", fontWeight: "bold", color: "#000" }}
        >
          Processing date
        </MDTypography>
      ),
      accessor: "Date",
    },
    {
      Header: () => (
        <MDTypography
          variant="h6"
          ml={3}
          style={{ fontSize: "16px", fontWeight: "bold", color: "#000" }}
        >
          Invoice (File)
        </MDTypography>
      ),
      accessor: "files",
    },
    {
      Header: () => (
        <MDTypography
          variant="h6"
          ml={4}
          style={{ fontSize: "16px", fontWeight: "bold", color: "#000" }}
        >
          Actions
        </MDTypography>
      ),
      accessor: "actions",
    },
  ];

  const rows =  expenseById?.map((expense) => {
    const formattedDate = new Date(expense.date).toLocaleDateString();
    const formatStartDate = expense.startdate
      ? new Date(expense.startdate).toLocaleDateString()
      : " ";
    const formatenddate = expense.end_date ? new Date(expense.end_date).toLocaleDateString() : " ";
    return {
      Apartment: (
        <MDTypography
          variant="caption"
          color="text"
          fontWeight="medium"
          style={{ fontSize: "14px" }}
        >
          {`${expense.property_name} `}
        </MDTypography>
      ),
      Expenses: (
        <MDTypography
          variant="caption"
          color="text"
          fontWeight="medium"
          style={{ fontSize: "14px" }}
        >
          {expense.title || ""}
        </MDTypography>
      ),
      amount: (
        <MDTypography
          variant="caption"
          color="text"
          fontWeight="medium"
          style={{ fontSize: "14px" }}
        >
          {`¥${(expense.expense_amount || 0).toLocaleString()}`}
        </MDTypography>
      ),
      Description: (
        <MDTypography
          variant="caption"
          color="text"
          fontWeight="medium"
          style={{ fontSize: "14px" }}
        >
          {expense.description || ""}
        </MDTypography>
      ),
      Date: (
        <MDTypography
          variant="caption"
          color="text"
          fontWeight="medium"
          style={{ fontSize: "14px" }}
        >
          {formattedDate || ""}
        </MDTypography>
      ),
      Startdate: (
        <MDTypography
          variant="caption"
          color="text"
          fontWeight="medium"
          style={{ fontSize: "14px" }}
        >
          {formatStartDate || ""}
        </MDTypography>
      ),
      end_date: (
        <MDTypography
          variant="caption"
          color="text"
          fontWeight="medium"
          style={{ fontSize: "14px" }}
        >
          {formatenddate || ""}
        </MDTypography>
      ),

      files: expense.files ? (
        <a href={expense.files} target="_blank" rel="noopener noreferrer">
          View Invoice
        </a>
      ) : (
        ""
      ),
      actions: (
        <MDTypography
          variant="caption"
          color="text"
          fontWeight="medium"
          style={{
            fontSize: "14px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div style={{ marginRight: "10px" }}>
            <IconButton sx={{ color: "#1e90ff" }} onClick={() => handleEdit(expense)}>
              <EditIcon />
            </IconButton>
          </div>
          <div>
            <IconButton sx={{ color: "red" }} onClick={() => handleDelete(expense)}>
              <DeleteIcon />
            </IconButton>
          </div>
        </MDTypography>
      ),
    };
  });

  return (
    <>
      <DashboardLayout>
        <Sidenav color="primary" brandName="RentRoll" routes={routes} />

        <MDBox pt={1} pb={3} mt={1}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  sx={{ backgroundColor: "#1e90ff" }}
                  borderRadius="lg"
                  coloredShadow="info"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <MDTypography variant="h5" color="white">
                    Add Expenses
                  </MDTypography>
                  <MDBox display="flex" justifyContent="space-between" alignItems="center">
                    <Button
                      style={{
                        color: "black",
                        border: "1px solid white",
                        background: "white",
                        marginRight: "10px",
                      }}
                      onClick={handleBack}
                    >
                       <ArrowBackIcon style={{ marginRight: "8px" }} /> Back
                    </Button>
                    <Button
                      style={{
                        color: "black",
                        border: "1px solid white",
                        background: "white",
                        marginRight: "10px",
                      }}
                      onClick={() => setIsExpensesModel(true)}
                    >
                      Add Expenses
                    </Button>
                    <TextField
                      placeholder="Search "
                      size="small"
                      sx={{
                        "& .MuiInputBase-root": {
                          backgroundColor: "#ffffff",
                          color: "#000000",
                          border: "none",
                        },
                        "& .MuiInputLabel-root": {
                          display: "none",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                        "& .MuiInputBase-input": {
                          padding: "8px",
                        },
                      }}
                      inputProps={{ "aria-label": "Search" }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                      onChange={handleSearch}
                      value={searchTerm}
                    />
                  </MDBox>
                </MDBox>
                <MDBox pt={3}>
                  {loading ? (
                    <MDTypography
                      variant="caption"
                      color="text"
                      fontWeight="medium"
                      align="center"
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100vh",
                      }}
                    >
                      <CircularProgress disableShrink sx={{ color: "#1e90FF" }} />
                    </MDTypography>
                  ) : expenseById.length === 0 ? (
                    <MDTypography variant="h6" align="center" m={2} sx={{ color: "red" }}>
                      Data not found.
                    </MDTypography>
                  ) : (
                    <DataTable
                      table={{ columns, rows }}
                      isSorted={false}
                      entriesPerPage={{ defaultValue: 15, entries: [10, 20, 30, 40, 50, 60] }}
                      showTotalEntries={false}
                      noEndBorder
                    />
                  )}
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <ExpensesModel
          isVisible={IsExpensesModel}
          Close={() => {
            setIsExpensesModel(false);
            setSelectedexpense(null);
          }}
          // fetchExpenses={fetchExpenses}
          handleExpenseById={refreshOnId}
          Selectedexpense={Selectedexpense}
        />
      </DashboardLayout>
    </>
  );
};

export default Index;
