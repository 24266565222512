import { Button, Container, IconButton, TextField, InputAdornment } from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Sidenav from "examples/Sidenav";
import React, { useEffect, useState } from "react";
import routes from "routes";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import axios from "axios";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EditIcon from "@mui/icons-material/Edit";
import CleaningUpdateModel from "./CleaningUpdateModel";
import Nav from "examples/Navbars/Navbar";
import CleaningModel from "./CleaningModel";
import CircularProgress from "@mui/material/CircularProgress";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";
import { ToastContainer, toast } from "react-toastify";
import { useGetCleaningByIdQuery , useDeleteCleaningModalMutation } from "slice/apiCleaningSlice";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";

const Cleaning = () => {
  const [cleaningData, setCleaningData] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [Selectedclean, setSelectedclean] = useState(null);
  const [IsExpensesModel, setIsExpensesModel] = useState(false);
  const Token = localStorage.getItem("token");
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredCleaner, setFilteredCleaner] = useState([]);
  const [cleaningDataByID, setCleaningDataById] = useState([]);
  const { property_id } = useParams();
  const { data, isLoading ,  refetch: refreshCleaningOnId } = useGetCleaningByIdQuery(property_id);
  const [deleteCleaningModal , {isLoading : deleteCleaningData , Error : deleteCleaningError}] = useDeleteCleaningModalMutation()
  const navigate = useNavigate();

  useEffect(() => {
    refreshCleaningOnId()
  }, [])

  useEffect(() => {
    if(isLoading){
      setLoading(true)
    }
    if (data?.data) {
      setCleaningDataById(data.data);
    }
    setLoading(false)
  }, [data]);

  // const getCleaningDataOnId = async () => {
  //   setLoading(true);
  //   try {
  //     const response = await axios.get(
  //       `${process.env.REACT_APP_API_PATH}/getcleaningdata/property_id/${property_id}`,
  //       {}
  //     );
  //     if (response.data.success && Array.isArray(response.data.data)) {
  //       setCleaningDataById(response.data.data);

  //     }
  //   } catch (error) {
  //     console.log(error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  // useEffect(() => {
  //   if (property_id) {
  //     getCleaningDataOnId();
  //   }
  // }, [property_id, modalVisible]);

  // const fetchCleaningData = async () => {
  //   try {
  //     setLoading(true);
  //     const response = await axios.get(`${process.env.REACT_APP_API_PATH}/getcleaningdata`);
  //     if (response.data.success && Array.isArray(response.data.data)) {
  //       setCleaningData(response.data.data);
  //     } else {
  //       console.error("Failed to fetch Cleaning data.");
  //     }
  //   } catch (error) {
  //     console.error("An error occurred while fetching Cleaning data:", error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  // useEffect(() => {
  //   fetchCleaningData();
  // }, []);

  const handleBack = (e) => {
    e.preventDefault();
    navigate(-1);
  };
  // useEffect(() => {
  //   getCleaningDataOnId();
  // }, []);
  const handleEdit = (clean) => {
    setModalVisible(true);
    setSelectedclean(clean);
  };
  const handleDelete = (clean) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this cleaning record?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Delete!",
    }).then((result) => {
      if (result.isConfirmed) {
        // axios
        //   .delete(
        //     `${process.env.REACT_APP_API_PATH}/deletecleaning/cleaningid/${clean.cleaning_id}`
        //   )
        deleteCleaningModal({
          cleaning_id : clean.cleaning_id
        })
          .then((res) => {
            if (res.data.status === 200) {
              refreshCleaningOnId();
              // Swal.fire("Done!", "Your action was successful", "success");
              toast.success(res.data.message);
            }
          })
          .catch((err) => {
            console.log(err);
            toast.error("An error occurred while deleting the record.");
          });
      }
    });
  };
  const columns = [
    {
      Header: () => (
        <MDTypography
          variant="h6"
          ml={3}
          width={110}
          style={{ fontSize: "16px", fontWeight: "bold", color: "#000" }}
        >
          Cleaning date
        </MDTypography>
      ),
      accessor: "cleaning_date",
    },
    {
      Header: () => (
        <MDTypography
          variant="h6"
          ml={3}
          style={{ fontSize: "16px", fontWeight: "bold", color: "#000" }}
        >
          Properties
        </MDTypography>
      ),
      accessor: "apartment",
    },
    {
      Header: () => (
        <MDTypography
          variant="h6"
          ml={3}
          style={{ fontSize: "16px", fontWeight: "bold", color: "#000" }}
        >
          Name
        </MDTypography>
      ),
      accessor: "name",
    },
    {
      Header: () => (
        <MDTypography
          variant="h6"
          ml={3}
          style={{ fontSize: "16px", fontWeight: "bold", color: "#000" }}
        >
          Email
        </MDTypography>
      ),
      accessor: "email",
    },
    {
      Header: () => (
        <MDTypography
          variant="h6"
          width={150}
          ml={3}
          style={{ fontSize: "16px", fontWeight: "bold", color: "#000" }}
        >
          Move out date
        </MDTypography>
      ),
      accessor: "move_out_date",
    },
    {
      Header: () => (
        <MDTypography
          variant="h6"
          ml={3}
          width={150}
          style={{ fontSize: "16px", fontWeight: "bold", color: "#000" }}
        >
          Check in time
        </MDTypography>
      ),
      accessor: "check_in_time",
    },
    {
      Header: () => (
        <MDTypography
          variant="h6"
          width={150}
          ml={3}
          style={{ fontSize: "16px", fontWeight: "bold", color: "#000" }}
        >
          Check out time
        </MDTypography>
      ),
      accessor: "check_out_time",
    },
    {
      Header: () => (
        <MDTypography
          variant="h6"
          ml={2.8}
          style={{ fontSize: "16px", fontWeight: "bold", color: "#000" }}
        >
          Guests
        </MDTypography>
      ),
      accessor: "guests",
    },

    {
      Header: () => (
        <MDTypography
          variant="h6"
          ml={3}
          style={{ fontSize: "16px", fontWeight: "bold", color: "#000" }}
        >
          Cleaner
        </MDTypography>
      ),
      accessor: "cleaner",
    },
    {
      Header: () => (
        <MDTypography
          variant="h6"
          ml={2}
          style={{ fontSize: "16px", fontWeight: "bold", color: "#000", width: "101px" }}
        >
          Cleaner email
        </MDTypography>
      ),
      accessor: "cleaner_email",
    },

    {
      Header: () => (
        <MDTypography
          variant="h6"
          ml={3}
          style={{ fontSize: "16px", fontWeight: "bold", color: "#000" }}
        >
          Todo
        </MDTypography>
      ),
      accessor: "todo",
    },

    {
      Header: () => (
        <MDTypography
          ml={2.8}
          variant="h6"
          style={{ fontSize: "16px", fontWeight: "bold", color: "#000" }}
        >
          Note
        </MDTypography>
      ),
      accessor: "note",
    },

    {
      Header: () => (
        <MDTypography
          variant="h6"
          ml={3}
          style={{ fontSize: "16px", fontWeight: "bold", color: "#000" }}
        >
          Origin
        </MDTypography>
      ),
      accessor: "origin",
    },
    {
      Header: () => (
        <MDTypography
          variant="h6"
          ml={3}
          style={{ fontSize: "16px", fontWeight: "bold", color: "#000" }}
        >
          Maintenance
        </MDTypography>
      ),
      accessor: "maintenance",
    },
    ...(property_id
      ? [
          {
            Header: () => (
              <MDTypography
                variant="h6"
                ml={4}
                style={{ fontSize: "16px", fontWeight: "bold", color: "#000" }}
              >
                Actions
              </MDTypography>
            ),
            accessor: "actions",
          },
        ]
      : []),
  ];
  const rows = (property_id ? filteredCleaner : cleaningData).map((clean) => {
    const departureDate = clean.departure_date ? new Date(clean.departure_date) : null;

    const formattedDate = departureDate
      ? new Intl.DateTimeFormat("en-IN", {
          day: "2-digit",
          month: "numeric",
          year: "numeric",
        }).format(departureDate)
      : "";
    const parseTime = (time) => {
      if (!time) return "";

      const timeParts = time.split(":");
      if (timeParts.length < 2) return "";

      const hours = parseInt(timeParts[0], 10);
      const minutes = parseInt(timeParts[1], 10);

      if (isNaN(hours) || isNaN(minutes)) return "";

      return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
    };

    const checkInTime = parseTime(clean.check_in_time);
    const checkOutTime = parseTime(clean.check_out_time);
    const formattedCleaningDate = clean.cleaning_date
      ? new Date(clean.cleaning_date).toLocaleDateString()
      : "";
    return {
      cleaning_date: (
        <MDTypography
          variant="caption"
          color="text"
          fontWeight="medium"
          style={{ fontSize: "14px" }}
        >
          {formattedCleaningDate || ""}
        </MDTypography>
      ),
      apartment: (
        <MDTypography
          variant="caption"
          color="text"
          fontWeight="medium"
          style={{ fontSize: "14px" }}
        >
          {`${clean.property_name || clean.apartment} `}
        </MDTypography>
      ),
      cleaner_email: (
        <MDTypography
          variant="caption"
          color="text"
          fontWeight="medium"
          style={{ fontSize: "14px" }}
        >
          {clean.cleaner_email || ""}
        </MDTypography>
      ),
      cleaner: (
        <MDTypography
          variant="caption"
          color="text"
          fontWeight="medium"
          style={{ fontSize: "14px" }}
        >
          {clean.cleaner_name || ""}
        </MDTypography>
      ),
      todo: (
        <MDTypography
          variant="caption"
          color="text"
          fontWeight="medium"
          style={{ fontSize: "14px" }}
        >
          {clean.todo || ""}
        </MDTypography>
      ),
      check_out_time: (
        <MDTypography
          variant="caption"
          color="text"
          fontWeight="medium"
          style={{ fontSize: "14px" }}
        >
          {checkOutTime}
        </MDTypography>
      ),
      note: (
        <MDTypography
          variant="caption"
          color="text"
          fontWeight="medium"
          style={{ fontSize: "14px" }}
        >
          {clean.notes || ""}
        </MDTypography>
      ),
      check_in_time: (
        <MDTypography
          variant="caption"
          color="text"
          fontWeight="medium"
          style={{ fontSize: "14px" }}
        >
          {checkInTime}
        </MDTypography>
      ),
      move_out_date: (
        <MDTypography
          variant="caption"
          color="text"
          fontWeight="medium"
          style={{ fontSize: "14px" }}
        >
          {formattedDate || ""}
        </MDTypography>
      ),
      guests: (
        <MDTypography
          variant="caption"
          color="text"
          fontWeight="medium"
          style={{ fontSize: "14px" }}
        >
          {clean.guest || ""}
        </MDTypography>
      ),
      name: (
        <MDTypography
          variant="caption"
          color="text"
          fontWeight="medium"
          style={{ fontSize: "14px" }}
        >
          {clean.name || clean.reservation_name}
        </MDTypography>
      ),
      email: (
        <MDTypography
          variant="caption"
          color="text"
          fontWeight="medium"
          style={{ fontSize: "14px" }}
        >
          {clean.email || clean.reservation_email}
        </MDTypography>
      ),
      origin: (
        <MDTypography
          variant="caption"
          color="text"
          fontWeight="medium"
          style={{ fontSize: "14px" }}
        >
          {clean.booking_source || ""}
        </MDTypography>
      ),
      maintenance: (
        <MDTypography
          variant="caption"
          color="text"
          fontWeight="medium"
          style={{ fontSize: "14px" }}
        >
          {clean.maintenance || ""}
        </MDTypography>
      ),
      actions: (
        <MDTypography
          variant="caption"
          color="text"
          fontWeight="medium"
          style={{
            fontSize: "14px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <div style={{ marginRight: "10px" }}>
            <IconButton sx={{ color: "#1e90ff" }} onClick={() => handleEdit(clean)}>
              <EditIcon />
            </IconButton>
          </div>
          <div>
            <IconButton sx={{ color: "red" }} onClick={() => handleDelete(clean)}>
              <DeleteIcon />
            </IconButton>
          </div>
        </MDTypography>
      ),
    };
  });
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };
  useEffect(() => {
    const filteredData = (property_id ? cleaningDataByID : cleaningData).filter((clean) => {
      const propertyname = (clean.property_name || clean.apartment || "")
        .toLowerCase()
        .includes(searchTerm.toLowerCase());
      const name = clean.reservation_name?.toLowerCase().includes(searchTerm.toLowerCase());
      const email = clean.reservation_email?.toLowerCase().includes(searchTerm.toLowerCase());
      const moveoutdates = clean.departure_date?.toLowerCase().includes(searchTerm.toLowerCase());
      const checkintime = clean.check_in_time?.toLowerCase().includes(searchTerm.toLowerCase());
      const checkouttime = clean.check_out_time?.toLowerCase().includes(searchTerm.toLowerCase());
      const guest = clean.guest?.toLowerCase().includes(searchTerm.toLowerCase());
      const cleaner = clean.cleaner_name?.toLowerCase().includes(searchTerm.toLowerCase());
      const cleaneremail = clean.cleaner_email?.toLowerCase().includes(searchTerm.toLowerCase());
      const todo = clean.todo?.toLowerCase().includes(searchTerm.toLowerCase());
      const note = clean.notes?.toLowerCase().includes(searchTerm.toLowerCase());
      const origin = clean.origin?.toLowerCase().includes(searchTerm.toLowerCase());
      return (
        propertyname ||
        name ||
        email ||
        moveoutdates ||
        checkintime ||
        checkouttime ||
        guest ||
        cleaner ||
        cleaneremail ||
        todo ||
        note ||
        origin
      );
    });
    setFilteredCleaner(filteredData);
  }, [searchTerm, cleaningDataByID]);
  return (
    <>
      {property_id ? (
        <DashboardLayout>
          <Sidenav color="primary" brandName="RentRoll" routes={routes} />
          <MDBox pt={1} pb={3} mt={1}>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Card>
                  <MDBox
                    mx={2}
                    mt={-3}
                    px={2}
                    py={3}
                    variant="gradient"
                    sx={{ backgroundColor: "#1e90ff" }}
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <Grid container spacing={2} alignItems="center" justifyContent="space-between">
                      <Grid item xs={12} md={8}>
                        <MDTypography variant="h5" color="white">
                          Cleaning Details
                        </MDTypography>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        md={4}
                        display="flex"
                        justifyContent="flex-end"
                        alignItems="center"
                        spacing={2}
                      >
                        <Button
                          style={{
                            color: "black",
                            border: "1px solid white",
                            background: "white",
                            marginRight: "10px",
                          }}
                          onClick={handleBack}
                        >
                          <ArrowBackIcon style={{ marginRight: "8px" }} /> Back
                        </Button>

                        <Button
                          style={{
                            color: "black",
                            border: "1px solid white",
                            background: "white",
                            whiteSpace: "nowrap", // Prevents wrapping
                            padding: "8px 16px", // Ensures enough space for the text
                          }}
                          onClick={() => setIsExpensesModel(true)}
                        >
                          Add cleaning
                        </Button>

                        <TextField
                          placeholder="Search"
                          size="small"
                          sx={{
                            "& .MuiInputBase-root": {
                              backgroundColor: "#ffffff",
                              color: "#000000",
                              border: "none",
                              padding: "3px 10px",
                              marginLeft: "10px",
                            },
                            "& .MuiInputLabel-root": {
                              display: "none",
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: "none",
                            },
                            "& .MuiInputBase-input": {
                              padding: "8px",
                            },
                          }}
                          inputProps={{ "aria-label": "Search" }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <SearchIcon />
                              </InputAdornment>
                            ),
                          }}
                          onChange={handleSearch}
                          value={searchTerm}
                        />
                      </Grid>
                    </Grid>
                  </MDBox>
                  {loading ? (
                    <MDTypography
                      variant="caption"
                      color="text"
                      fontWeight="medium"
                      align="center"
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100vh",
                      }}
                    >
                      <CircularProgress disableShrink sx={{ color: "#1e90FF" }} />
                    </MDTypography>
                  ) : filteredCleaner.length === 0 ? (
                    <MDTypography
                      variant="h6"
                      color="text"
                      fontWeight="medium"
                      align="center"
                      my={3}
                      style={{ height: "100%", color: "red" }}
                    >
                      No cleaning data available.
                    </MDTypography>
                  ) : (
                    <MDBox pt={3}>
                      <DataTable
                        table={{ columns, rows }}
                        isSorted={false}
                        entriesPerPage={{ defaultValue: 15, entries: [10, 20, 30, 40, 50, 60] }}
                        showTotalEntries={false}
                        noEndBorder
                      />
                    </MDBox>
                  )}
                </Card>
              </Grid>
            </Grid>
            <CleaningUpdateModel
              isVisible={modalVisible}
              fetchCleaningData={refreshCleaningOnId}
              getCleaningDataOnId={refreshCleaningOnId}
              Close={() => {
                setModalVisible(false);
                setSelectedclean(null);
              }}
              Selectedclean={Selectedclean}
            />
          </MDBox>
        </DashboardLayout>
      ) : (
        <>
          <Nav />
          <MDBox pt={0.5} pb={3} mt={1}>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Card>
                  <MDBox
                  // mx={2}
                  // mt={-3}
                  // py={3}
                  // px={2}
                  // variant="gradient"
                  // sx={{ backgroundColor: "#1e90ff" }}
                  // borderRadius="lg"
                  // coloredShadow="info"
                  >
                    <Grid container spacing={2} alignItems="center" justifyContent="space-between">
                      {/* <Grid item>
                        <MDTypography variant="h6" color="white">
                          Cleaning Details
                        </MDTypography>
                      </Grid> */}

                      <Grid
                        item
                        xs={12}
                        md={4}
                        ml={2}
                        mt={1}
                        display="flex"
                        justifyContent="flex-start"
                        alignItems="left"
                        spacing={2}
                      >
                        {Token ? (
                          <Button
                            style={{
                              color: "white",
                              border: "1px solid white",
                              background: "#1e90ff",
                              marginRight: "10px",
                            }}
                            onClick={handleBack}
                          >
                            <ArrowBackIcon style={{ marginRight: "8px" }} /> Back
                          </Button>
                        ) : (
                          " "
                        )}
                        {/* <TextField
                          placeholder="Search "
                          size="small"
                          sx={{
                            "& .MuiInputBase-root": {
                              backgroundColor: "#ffffff",
                              color: "#000000",
                              border: "none",
                              padding: "3px 10px",
                            },
                            "& .MuiInputLabel-root": {
                              display: "none",
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: "none",
                            },
                            "& .MuiInputBase-input": {
                              padding: "8px",
                            },
                          }}
                          inputProps={{ "aria-label": "Search" }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <SearchIcon />
                              </InputAdornment>
                            ),
                          }}
                          onChange={handleSearch}
                          value={searchTerm}
                        /> */}
                      </Grid>
                    </Grid>
                  </MDBox>
                  {loading ? (
                    <MDTypography
                      variant="caption"
                      color="text"
                      fontWeight="medium"
                      align="center"
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100vh",
                      }}
                    >
                      <CircularProgress disableShrink sx={{ color: "#1e90FF" }} />
                    </MDTypography>
                  ) : cleaningData.length === 0 ? (
                    <MDTypography
                      variant="h6"
                      color="text"
                      fontWeight="medium"
                      align="center"
                      my={3}
                      style={{ height: "100%", color: "red" }}
                    >
                      No cleaning data available...
                    </MDTypography>
                  ) : (
                    <MDBox pt={3}>
                      <DataTable
                        table={{ columns, rows }}
                        isSorted={false}
                        entriesPerPage={false}
                        pagination={false}
                        // showTotalEntries={false}
                        noEndBorder
                      />
                    </MDBox>
                  )}
                </Card>
              </Grid>
            </Grid>
            <CleaningUpdateModel
              isVisible={modalVisible}
              fetchCleaningData={refreshCleaningOnId}
              getCleaningDataOnId={refreshCleaningOnId}
              Close={() => {
                setModalVisible(false);
                setSelectedclean(null);
              }}
              Selectedclean={Selectedclean}
            />
          </MDBox>
        </>
      )}
      <CleaningModel
        isVisible={IsExpensesModel}
        Close={() => setIsExpensesModel(false)}
        fetchCleaningData={refreshCleaningOnId}
        getCleaningDataOnId={refreshCleaningOnId}
      />
    </>
  );
};

export default Cleaning;
