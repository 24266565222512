import { createApi , fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const apiManageBookingPaymentMethod = createApi({
    reducerPath : 'ManageBookingPaymentMethod',
    baseQuery : fetchBaseQuery({
        baseUrl : process.env.REACT_APP_API_PATH,
    }),
    endpoints : (builder)=>({
         dropDown : builder.query({
            query : ()=> `\getdrop-down`
         }),
         updateDropDow : builder.mutation({
            query : ({selectedId})=>({
                url : `/update-booking-source/${selectedId}`,
                method : "PUT",
                headers : {},
            })
         })
    })
})
 export const {useDropDownQuery , useUpdateDropDowMutation
} = apiManageBookingPaymentMethod