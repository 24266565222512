import { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "assets/theme";
import "Styles.css";
import "react-toastify/dist/ReactToastify.css";
const Basic = lazy(() => import("layouts/authentication/sign-in"));
const Forget = lazy(() => import("layouts/authentication/sign-in/Forget"));
const ResetPassword = lazy(() => import("layouts/authentication/sign-in/ResetPassword"));

// const MonthlyData = lazy(() => import("components/MonthlyReport/index"));
// const RoomReservationData = lazy(() => import("components/properties/RoomReservationData"));
// const Changepassword = lazy(() => import("layouts/authentication/sign-in/Changepassword"));
// const ExpensessData = lazy(() => import("./components/Expenses/index"));
// const Overview = lazy(() => import("components/Overview/index"));
// const Cleaning = lazy(() => import("components/Cleaning/index"));
// const Profile = lazy(() => import("components/userProfile/index"));
import ListProperties from "components/properties/ListProperties";
import PublicRoutes from "PublicRoutes";
import CircularProgress from "@mui/material/CircularProgress";
import ManageReservationList from "components/ModelAdd/ManageReservationList";
import Changepassword from "./layouts/authentication/sign-in/Changepassword";
import PrivateRoutes from "PrivateRoutes";
import Overview from "components/Overview";
import ExpensessData from "./components/Expenses/index";
import Profile from "./components/userProfile/index";
import RoomReservationData from "components/properties/RoomReservationData";
import Cleaning from "components/Cleaning";
import MonthlyData from "components/MonthlyReport/index";
import PublicCleaning from "components/Cleaning/PublicCleaning";
export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Suspense
        fallback={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
              width: "100%",
            }}
          >
            <CircularProgress disableShrink sx={{ color: "#1e90FF" }} />
          </div>
        }
      >
        <Routes>
          <Route element={<PublicRoutes />}>
            <Route path="/" element={<Basic />} />
            <Route path="/authentication/sign-in/forget" element={<Forget />} />
            <Route path="/authentication/sign-in/reset/:token" element={<ResetPassword />} />
          </Route>
          <Route path="/public/cleaning/" target="_blank" element={<PublicCleaning />} />
          <Route element={<PrivateRoutes />}>
            <Route path="/properties/add-properties" element={<ListProperties />} />
            <Route path="/properties/list-properties" element={<ListProperties />} />
            <Route path="/change/password" element={<Changepassword />} />
            <Route path="/monthly" element={<MonthlyData />} />
            <Route path="/dashboard" element={<Overview />} />
            <Route path="/expense/:property_id" element={<ExpensessData />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/room-reservation/:property_id" element={<RoomReservationData />} />
            <Route path="/manage-reservation" element={<ManageReservationList />} />
            <Route path="/cleaning/:property_id" element={<Cleaning />} />
          </Route>
        </Routes>
      </Suspense>
    </ThemeProvider>
  );
}
