import { fetchBaseQuery , createApi } from "@reduxjs/toolkit/query/react";

 export const  apiExpenseSilce = createApi({
      reducerPath : "apiExpenseSlice",
      baseQuery : fetchBaseQuery({
        baseUrl : process.env.REACT_APP_API_PATH,
      }),
      endpoints : (builder)=>({
        getExpenseOnId : builder.query({
            query : (property_id) =>  `/getexpenseList/property_id/${property_id}`
        })
     })

})
export const {useGetExpenseOnIdQuery} = apiExpenseSilce
