import { Card, Grid, IconButton, Chip, TextField, Box, Icon, Autocomplete } from "@mui/material";
import axios from "axios";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Sidenav from "examples/Sidenav";
import React, { useEffect, useState } from "react";
import routes from "routes";
import { ToastContainer, toast } from "react-toastify";
import EditIcon from "@mui/icons-material/Edit";
import CircularProgress from "@mui/material/CircularProgress";
import { useDropDownQuery, useUpdateDropDowMutation } from "slice/ManageBookingPaymentMethod";

const ManageReservationList = () => {
  const [bookingSources, setBookingSources] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isEditModeAddress, setEditModeAddress] = useState(false);
  const [isEditModeBio, setEditModeBio] = useState(false);
  const [selectedValues, setSelectedValues] = useState({
    bookingSources: [],
    paymentMethods: [],
  });
  const [inputValue, setInputValue] = useState({
    bookingSources: "",
    paymentMethods: "",
  });

  const [currentInput, setCurrentInput] = useState("");
  const { data, isFetching , refetch :fetchDropDowndata } = useDropDownQuery();
  const [updateDropDow, { isFetching: updateDropDownFetching, Error }] = useUpdateDropDowMutation()
 
  useEffect(() => {
    if (isFetching) {
      setLoading(true);
    }
    try {
      if (data?.data) {
        const sortedData = (data.data || []).sort((a, b) =>
          a.booking_source?.localeCompare(b?.booking_source)
        );
        setBookingSources(sortedData);
        setSelectedValues({
          bookingSources: sortedData[0].booking_source,
          paymentMethods: sortedData[0].payment_method,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [data, isFetching, Error]);

  // const getBookingData = async () => {
  //   try {
  //     setLoading(true);
  //     const response = await axios.get(`${process.env.REACT_APP_API_PATH}/getdrop-down`);
  //     const sortedData = (response.data.data || []).sort((a, b) =>
  //       a.booking_source?.localeCompare(b?.booking_source)
  //     );
  //     console.log(sortedData, "sortedData", "data.data");
  //     setBookingSources(sortedData);
  //     setSelectedValues({
  //       bookingSources: sortedData[0].booking_source,
  //       paymentMethods: sortedData[0].payment_method,
  //     });
  //   } catch (error) {
  //     console.error("Failed to fetch booking sources!", error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const handleAutocompleteChange = (event, value, field) => {
    setSelectedValues((prevValues) => ({
      ...prevValues,
      [field]: value,
    }));
  };

  const handleInputChange = (event, newValue, fieldName) => {
    setInputValue((prevInputValue) => ({ ...prevInputValue, [fieldName]: newValue }));
  };
  const handleDeleteChip = (field, chipToDelete) => {
    setSelectedValues((prevValues) => ({
      ...prevValues,
      [field]: prevValues[field].filter((chip) => chip !== chipToDelete),
    }));
  };

  const handleUpdate = async () => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_PATH}/update-booking-source/${selectedId}`, 
         {
          booking_source: selectedValues.bookingSources,
          payment_method: selectedValues.paymentMethods,
        }
      );
      if (response.data.success) {
        toast.success(response.data.message);
        // getBookingData();
        fetchDropDowndata()
        handleClose();
      } else {
        toast.error(response.data.message);
      }
    } catch (err) {
      console.error("Error updating or adding data:", err);
      toast.error(err.response?.data?.message || "An unexpected error occurred!");
    }
  };

  const renderChips = (field) => {
    return selectedValues[field].map((chip, index) => (
      <Chip
        key={index}
        label={chip}
        onDelete={() => handleDeleteChip(field, chip)}
        style={{ marginRight: 5, marginBottom: 5 }}
      />
    ));
  };

  // useEffect(() => {
  //   getBookingData();
  // }, []);

  const handleEditClick = () => {
    setSelectedId(bookingSources[0]?.dropdown_id);
    setEditModeAddress(!isEditModeAddress);
  };

  const handleEditpaymentClick = () => {
    setSelectedId(bookingSources[0]?.dropdown_id);
    setEditModeBio(!isEditModeBio);
  };

  const handleClose = () => {
    setEditModeAddress(false);
    setEditModeBio(false);
  };

  const handleButtonClick = (fieldName) => {
    const currentInputValue = inputValue[fieldName] || "";
    const trimmedInput = currentInputValue.trim();

    if (trimmedInput && !selectedValues[fieldName].includes(trimmedInput)) {
      setSelectedValues({
        ...selectedValues,
        [fieldName]: [...selectedValues[fieldName], trimmedInput],
      });

      setInputValue({ ...inputValue, [fieldName]: "" });
    } else {
      const fieldValues = selectedValues[fieldName] || [];

      const editedValues = fieldValues.map((value) => {
        if (value === currentInput) {
          return trimmedInput;
        } else {
          return value;
        }
      });
      setSelectedValues({ ...selectedValues, [fieldName]: editedValues });
    }
  };

  return (
    <>
      <DashboardLayout>
        <Sidenav color="primary" brandName="RentRoll" routes={routes} />
        <MDBox pt={2} pb={3} sx={{ pt: { xs: "45px", sm: "40px" } }}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3.8}
                  px={2}
                  variant="gradient"
                  sx={{ backgroundColor: "#1e90ff" }}
                  borderRadius="lg"
                  coloredShadow="info"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <MDTypography variant="h5" color="white">
                    Manage Lists
                  </MDTypography>
                </MDBox>
                {loading ? (
                  <MDTypography
                    variant="caption"
                    color="text"
                    fontWeight="medium"
                    align="center"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100vh",
                    }}
                  >
                    <CircularProgress disableShrink sx={{ color: "#1e90FF" }} />
                  </MDTypography>
                ) : (
                  <>
                    <Grid container spacing={1} mt={5}>
                      <Grid item xs={12} md={12} xl={12}>
                        <MDBox>
                          <MDBox
                            component="fieldset"
                            px={6}
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              border: "none",
                              px: { xs: 1, sm: 6 }, 
                              py: { xs: 1, sm: 3 },
                            }}
                          >
                            <Box
                              component="fieldset"
                              className="filedset_border"
                              sx={{ fontSize: "1.5rem", fontWeight: "bold" }}
                            >
                              <legend style={{ marginLeft: "15px" }}> Payment Method </legend>
                              <Box>
                                <Icon
                                  variant="text"
                                  color="info"
                                  onClick={handleEditpaymentClick}
                                  sx={{
                                    cursor: isEditModeBio === undefined ? "pointer" : "default",
                                    float: "right",
                                    marginRight: "20px",
                                    marginTop: "-2%",
                                  }}
                                >
                                  {isEditModeBio
                                    ? ""
                                    : isEditModeBio !== undefined && (
                                        <EditIcon
                                          sx={{
                                            cursor: "pointer",
                                            fontSize: "20px !important",
                                          }}
                                        />
                                      )}
                                </Icon>
                                <Grid container spacing={1} style={{ marginLeft: "10px" }}>
                                  <Grid item xs={12} md={11} xl={11}>
                                    <MDBox mb={1}>
                                      {isEditModeBio ? (
                                        <>
                                          <Autocomplete
                                            freeSolo
                                            multiple
                                            options={[]}
                                            value={selectedValues?.paymentMethods || []}
                                            onChange={(event, value) =>
                                              handleAutocompleteChange(
                                                event,
                                                value,
                                                "paymentMethods",
                                                10,
                                                5
                                              )
                                            }
                                            inputValue={inputValue.paymentMethods}
                                            onInputChange={(event, newValue) =>
                                              handleInputChange(event, newValue, "paymentMethods")
                                            }
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                id="outlined-basic"
                                                label="payment Methods"
                                                variant="outlined"
                                                style={{ width: "100%", marginBottom: "20px" }}
                                                onChange={(event) =>
                                                  setCurrentInput(event.target.value)
                                                }
                                              />
                                            )}
                                          />
                                        </>
                                      ) : (
                                        <MDTypography
                                          display="inline"
                                          variant="body2"
                                          verticalAlign="middle"
                                        >
                                          {bookingSources[0]?.payment_method &&
                                            bookingSources[0].payment_method.map((method, idx) => (
                                              <Chip
                                                key={idx}
                                                label={method}
                                                variant="outlined"
                                                style={{
                                                  marginRight: "5px",
                                                  marginBottom: "5px",
                                                  cursor: "pointer",
                                                  fontSize: "15px",
                                                  fontWeight: "bolder",
                                                }}
                                              />
                                            ))}
                                        </MDTypography>
                                      )}
                                      {isEditModeBio && (
                                        <>
                                          <MDBox mt={2} mb={2}>
                                            {/* <MDButton
                                            component="a"
                                            style={{
                                              backgroundColor: "transparent",
                                              color: "#0d6efd",
                                              textDecoration: "underline",
                                              textTransform: "none",
                                              fontSize: "16px",
                                            }}
                                            onClick={() => handleButtonClick("paymentMethods")}
                                          >
                                            Add
                                          </MDButton> */}
                                            <MDButton
                                              style={{
                                                backgroundColor: "#0d6efd",
                                                color: "white",
                                              }}
                                              variant="gradient"
                                              onClick={handleUpdate}
                                            >
                                              Update
                                            </MDButton>
                                            <MDButton
                                              component="a"
                                              style={{
                                                backgroundColor: "transparent",
                                                color: "#0d6efd",
                                                textDecoration: "underline",
                                                textTransform: "none",
                                                fontSize: "16px",
                                              }}
                                              onClick={handleClose}
                                            >
                                              Cancel
                                            </MDButton>
                                          </MDBox>
                                        </>
                                      )}
                                    </MDBox>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Box>
                          </MDBox>
                        </MDBox>
                      </Grid>
                    </Grid>
                    <Grid container spacing={1} my={5}>
                      <Grid item xs={12} md={12} xl={12}>
                        <MDBox>
                          <MDBox
                            component="fieldset"
                            px={6}
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              border: "none",
                              px: { xs: 1, sm: 6 }, 
                              py: { xs: 1, sm: 3 },
                            }}
                          >
                            <Box
                              component="fieldset"
                              className="filedset_border"
                              sx={{ fontSize: "1.5rem", fontWeight: "bold" }}
                            >
                              <legend style={{ marginLeft: "15px" }}> Booking Source </legend>
                              <Box>
                                <Icon
                                  variant="text"
                                  color="info"
                                  onClick={handleEditClick}
                                  sx={{
                                    cursor: isEditModeAddress === undefined ? "pointer" : "default",
                                    float: "right",
                                    marginRight: "20px",
                                    marginTop: "-2%",
                                    
                                  }}
                                >
                                  {isEditModeAddress
                                    ? ""
                                    : isEditModeAddress !== undefined && (
                                        <EditIcon
                                          sx={{
                                            cursor: "pointer",
                                            fontSize: "20px !important",
                                          }}
                                        />
                                      )}
                                </Icon>
                                <Grid container spacing={1} style={{ marginLeft: "10px" }}>
                                  <Grid item xs={12} md={11} xl={11}>
                                    <MDBox mb={1}>
                                      {isEditModeAddress ? (
                                        <>
                                          <Autocomplete
                                            freeSolo
                                            multiple
                                            options={[]}
                                            value={selectedValues?.bookingSources || []}
                                            onChange={(event, value) =>
                                              handleAutocompleteChange(
                                                event,
                                                value,
                                                "bookingSources",
                                                10,
                                                5
                                              )
                                            }
                                            inputValue={inputValue.bookingSources}
                                            onInputChange={(event, newValue) =>
                                              handleInputChange(event, newValue, "bookingSources")
                                            }
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                id="outlined-basic"
                                                label="Booking Sources"
                                                variant="outlined"
                                                style={{ width: "100%", marginBottom: "20px" }}
                                                onChange={(event) =>
                                                  setCurrentInput(event.target.value)
                                                }
                                              />
                                            )}
                                          />
                                        </>
                                      ) : (
                                        <MDTypography
                                          display="inline"
                                          variant="body2"
                                          verticalAlign="middle"
                                        >
                                          {bookingSources[0]?.booking_source &&
                                            bookingSources[0].booking_source.map((method, idx) => (
                                              <Chip
                                                key={idx}
                                                label={method}
                                                variant="outlined"
                                                style={{
                                                  marginRight: "5px",
                                                  marginBottom: "5px",
                                                  cursor: "pointer",
                                                  fontSize: "16px",
                                                  fontWeight: "bolder",
                                                }}
                                              />
                                            ))}
                                        </MDTypography>
                                      )}
                                      {isEditModeAddress && (
                                        <>
                                          <MDBox mt={2} mb={2}>
                                            {/* <MDButton
                                            component="a"
                                            style={{
                                              backgroundColor: "transparent",
                                              color: "#0d6efd",
                                              textDecoration: "underline",
                                              textTransform: "none",
                                              fontSize: "16px",
                                            }}
                                            onClick={() => handleButtonClick("bookingSources")}
                                          >
                                            Add
                                          </MDButton> */}
                                            <MDButton
                                              style={{
                                                backgroundColor: "#0d6efd",
                                                color: "white",
                                              }}
                                              variant="gradient"
                                              onClick={handleUpdate}
                                            >
                                              Update
                                            </MDButton>
                                            <MDButton
                                              component="a"
                                              style={{
                                                backgroundColor: "transparent",
                                                color: "#0d6efd",
                                                textDecoration: "underline",
                                                textTransform: "none",
                                                fontSize: "16px",
                                              }}
                                              onClick={handleClose}
                                            >
                                              Cancel
                                            </MDButton>
                                          </MDBox>
                                        </>
                                      )}
                                    </MDBox>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Box>
                          </MDBox>
                        </MDBox>
                      </Grid>
                    </Grid>
                  </>
                )}
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </DashboardLayout>
      <ToastContainer limit={1} />
    </>
  );
};

export default ManageReservationList;
