import { Typography } from "@mui/material";
import ApartmentIcon from "@mui/icons-material/Apartment";
import AddHomeIcon from "@mui/icons-material/AddHome";
import ListAltIcon from "@mui/icons-material/ListAlt";
import DashboardIcon from "@mui/icons-material/Dashboard";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import BookOnlineIcon from "@mui/icons-material/BookOnline";
import MoneyIcon from "@mui/icons-material/Money";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import { OpenInBrowser } from "@mui/icons-material";
const routes = [
  {
    type: "collapse",
    name: <Typography sx={{ fontSize: "16px" }}>Dashboard</Typography>,
    key: "dashboard",
    icon: <DashboardIcon fontSize="small" />,
    route: "/dashboard",
  },
  {
    type: "collapse",
    name: <Typography sx={{ fontSize: "16px" }}>Properties</Typography>,
    key: "properties/add-properties",
    route: "/properties/add-properties",
    icon: <ApartmentIcon fontSize="small" />,
  },
  {
    type: "collapse",
    name: <Typography sx={{ fontSize: "16px" }}>Manage Lists</Typography>,
    key: "manage-reservation",
    icon: <PlaylistAddIcon fontSize="small" />,
    route: "/manage-reservation",
  },
  {
    type: "collapse",
    name: <Typography sx={{ fontSize: "16px" }}>Monthly Report</Typography>,
    route: "/monthly",
    key: "monthly",
    icon: <CalendarMonthIcon fontSize="small" />,
  },
  {
    type: "collapse",
    name: (
      <Typography
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          window.open("/public/cleaning", "_blank");
        }}
        sx={{ fontSize: "16px", cursor: "pointer" }}
      >
        Public Cleaning <br /> Schedule
      </Typography>
    ),
    key: "cleaning",
    icon: <CleaningServicesIcon fontSize="small" />,
    route: "#",
    target: "_blank",
  },
  {
    type: "collapse",
    name: <Typography sx={{ fontSize: "16px" }}>Profile</Typography>,
    key: "profile",
    icon: <AccountCircleIcon fontSize="small" />,
    route: "/profile",
  },
];

export default routes;
