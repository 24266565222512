import * as React from "react";
import Modal from "@mui/material/Modal";
import { useFormik } from "formik";
import * as Yup from "yup";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { Button, Card, Grid, TextField, FormControl, Box } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import * as pdfjsLib from "pdfjs-dist";
import { useParams } from "react-router";
import { useEditReservationDataMutation, useAddReservationDataMutation, useGetReservationOnIdQuery } from "slice/apiReservationSlice";
import { useAddCleaningDataMutation } from "slice/apiCleaningSlice";
import { useUpdateCleaningDataMutation } from "slice/apiCleaningSlice";
import { useGetDropDownQuery } from "slice/apiDropDownSlice";


dayjs.extend(timezone);
if (typeof pdfjsLib.GlobalWorkerOptions !== "undefined") {
  pdfjsLib.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.10.377/pdf.worker.min.js`;
}

const style = {
  position: "absolute",
  top: { xs: 450, sm: 450, md: 450, xl: 450 },
  width: 600,
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  pt: 2,
  pb: 3,
};

const ReservationModel = ({ isVisible, Close, selectedProperty, roomId }) => {
  const [properties, setProperties] = useState([]);
  const [IsSelectProperty, setSelectProperty] = useState(null);
  const [bookingSources, setBookingSources] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [cleanerData, setCleanerData] = useState()
  const [bookingSourceOption, setBookingSourceOption] = useState([]);
  const [cleaning_id, setCleaning_id] = useState();
  const [paymentMethodsOptions, setPaymentOptions] = useState([]);
  const { property_id } = useParams();
  const [addReservationData, { isLoading: addReservationLoading, error: useAddReservationError }] = useAddReservationDataMutation()
  const [editReservationData, { isLoading, error }] = useEditReservationDataMutation();
  const [addCleaningData, { isLoading: updateCleanerLoad, error: updateCleanerLoader }] = useAddCleaningDataMutation()
  const { data, isLoading: getDropDownLloading, error: getDropdownError } = useGetDropDownQuery()
  const { refetch: getReservationDataOnId } = useGetReservationOnIdQuery(property_id)
  const [updateCleaningData, { Loading, Error }] = useUpdateCleaningDataMutation();
  const PropertiesId = roomId?.property_id || IsSelectProperty;
  const shouldShowModal =
    location.pathname !== "/properties/list-properties" &&
    location.pathname !== "/properties/add-properties";
  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    email: Yup.string().required("Email is required").email(),
    rent_amount: Yup.number().positive(),

    arrived_date: Yup.date().required("Arrival date is required"),
    departure_date: Yup.date()
      .min(Yup.ref("arrived_date"), "Departure must be after arrival")
      .required("Departure date is required"),
  });
  const validationDataSchema = Yup.object({
  });


  useEffect(() => {
    getBookingData()
  }, [])


  useEffect(() => {
    const getData = async () => {
      const response = await axios.get(`${process.env.REACT_APP_API_PATH}/getcleaningdata/property_id/${property_id}`)
      const filterResponse = response.data.data.filter((item) => {
        return item?.departure_date === selectedProperty?.departure_date
      });
      setCleaning_id(filterResponse[0]?.cleaning_id)
      setCleanerData(filterResponse[0])
    }

    if (property_id) {
      getData()
    }
  }, [property_id, isVisible, cleaning_id])

  const getBookingData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_PATH}/getdrop-down`);
      if (Array.isArray(response.data.data)) {
        const validBookingSources = response.data.data.filter((item) => item?.booking_source);
        const validpaymetMethod = response.data.data.filter((item) => item?.payment_method);
        setBookingSources(validBookingSources);
        setPaymentMethods(validpaymetMethod);
      } else {
        console.error("Unexpected response format", response.data);
      }
    } catch (error) {
      console.error("Failed to fetch booking sources!", error);
    }
  };

  const [parentState, setParentState] = useState({
    name: "",
    email: "",
    phone: "",
    arrived_date: "",
    departure_date: "",
    check_in_time: "",
    check_out_time: "",
    guest: "",
    notes: "",
    booking_source: "",
    currency: "",
    amount: "",
    rent_amount: "",
    deposit_amount: "",
    payment_method: "",
    total_stay: "",
    payment_receipt: "",
    property: PropertiesId,
  });

  const formik = useFormik({
    initialValues: parentState,

    validationSchema: shouldShowModal
      ? validationSchema.concat(validationDataSchema)
      : validationSchema,
    onSubmit: async (values) => {
      const { departure_date } = values
      
      const nextDay = new Date(departure_date)
      nextDay.setDate(nextDay.getDate() + 1);
     const departure_date1 = nextDay.toISOString().split("T")[0]
      try {
        let response;

        if (selectedProperty) {
          response = await editReservationData({
            PropertiesId,
            reservation_id: selectedProperty.reservation_id,
            values,
          }).unwrap();
          if (cleaning_id && departure_date) {
            const formData = {
              ...cleanerData,
              cleaning_date: departure_date1,
              property_id: property_id,
              // room_id:RoomId,
            };
            response = await updateCleaningData({
              cleaningData: cleaning_id,
              FormData: formData
            })
          }

        }


        else {
          response = await axios.post(
            `${process.env.REACT_APP_API_PATH}/property/${property_id || PropertiesId}/reservation`,
            values
          );
          if (response.status === 200 || response.data.status === 200) {
            const cleaningData = {
              property_id: PropertiesId || property_id,
              cleaning_date: departure_date1,
            };
            await axios.post(
              `${process.env.REACT_APP_API_PATH}/cleaningdata/${PropertiesId || property_id}`,
              cleaningData
            );
          }
        }
        if ((response && response.status === 200) || response.data.status === 200) {
          toast.success(
            selectedProperty
              ? "Room Reservation data updated successfully!"
              : "Reservation created successfully!"
          );
          CloseModal();
          // addReservationData(values);
          getReservationDataOnId()
          formik.resetForm();
        }
      } catch (error) {
        console.error("Failed to add/update room data. Please try again.", error);
        if (error.response && error.response.data) {
          toast.error(error.response.data.message);
        }
      }
    },
  });

  useEffect(() => {
    if (selectedProperty) {
      let cleaner = "";
      if (selectedProperty.cleaner) {
        if (typeof selectedProperty.cleaner === "string") {
          try {
            cleaner = JSON.parse(selectedProperty.cleaner);
          } catch (error) {
            console.error("Failed to parse cleaner data:", error);

            cleaner = { name: selectedProperty.cleaner.trim() };
          }
        } else {
          cleaner = selectedProperty.cleaner;
        }
      }

      formik.setValues({
        name: selectedProperty?.name || "",
        email: selectedProperty?.email || "",
        phone: selectedProperty?.phone || "",
        arrived_date: dayjs(selectedProperty.arrived_date).format("YYYY-MM-DD") || "",
        departure_date: dayjs(selectedProperty.departure_date).format("YYYY-MM-DD") || "",
        check_in_time: selectedProperty.check_in_time || "",
        check_out_time: selectedProperty.check_out_time || "",
        guest: selectedProperty?.guest || "",
        notes: selectedProperty?.notes || "",
        booking_source: selectedProperty?.booking_source || "",
        currency: selectedProperty?.currency || "",
        amount: selectedProperty?.amount || "",
        cleaner: cleaner || "",
        property: {
          property_id: selectedProperty.property_id,
          property_name: selectedProperty.property_name,
        },
        rent_amount: selectedProperty.rent_amount || "",
        deposit_amount: selectedProperty.deposit_amount || "",
        payment_method: selectedProperty.payment_method || "",
        total_stay: selectedProperty.total_stay || "",
      });
      setSelectProperty(selectedProperty.property_id || roomId.property_id);
    } else {
      formik.resetForm();
    }
  }, [selectedProperty]);
  const CloseModal = () => {
    formik.resetForm();
    Close();
  };

  const isFormFilled = Object.values(formik.values).some(
    (value) => typeof value === "string" && value.trim() !== ""
  );
  const handleDateChange = (name, value) => {
    formik.setFieldValue(name, value);
  };

  const customOnchange = (value, mainValue) => {
    setParentState({
      ...parentState,
      booking_source: value,
    });
  };
  const customPaymentOnchange = (value, mainValue) => {
    setParentState({
      ...parentState,
      payment_method: value,
    });
  };
  useEffect(() => {
    if (formik.values.arrived_date && formik.values.departure_date) {
      const arrivedDate = dayjs(formik.values.arrived_date);
      const departureDate = dayjs(formik.values.departure_date);

      if (arrivedDate.isValid() && departureDate.isValid()) {
        const totalStay = departureDate.isAfter(arrivedDate)
          ? departureDate.diff(arrivedDate, "days")
          : 0;

        formik.setFieldValue("total_stay", totalStay);
      }
    }
  }, [formik.values.arrived_date, formik.values.departure_date]);

  useEffect(() => {
    let options = [];
    if (bookingSources[0]?.booking_source) {
      options = bookingSources[0].booking_source
        .sort((a, b) => a.localeCompare(b))
        .map((value) => ({ label: value }));
    }
    setBookingSourceOption(options || "");

    let paymentOptions = [];
    if (paymentMethods[0]?.payment_method) {
      paymentOptions = paymentMethods[0].payment_method
        .sort((a, b) => a.localeCompare(b))
        .map((value) => ({ label: value }));
    }
    setPaymentOptions(paymentOptions || "");
  }, [bookingSources, paymentMethods]);

  return (
    <>
      <Modal
        open={isVisible}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <MDBox
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(0,0,0,0.5)",
            }}

          >
            <Card
              sx={{
                ...style,
                width: {
                  xs: "90%",
                  sm: "80%",
                  md: "500px",
                  lg: "60%",
                  xl: "850px",
                },
              }}
            >
              <MDBox
              >
                <MDBox
                  borderRadius="lg"
                  coloredShadow="info"
                  mx={2}
                  mt={-3}
                  p={2}
                  mb={1}
                  textAlign="center"
                  sx={{
                    backgroundColor: "#1e90ff",

                  }}
                >
                  <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                    {selectedProperty ? "Edit Reservation" : "Add Reservation"}
                  </MDTypography>
                </MDBox>
                <MDBox pt={4} pb={3} px={3} sx={{
                  // maxHeight: { xs: "120vh", sm: '100%' },
                  overflowY: "auto",
                  // overflowX: "hidden",
                }}>
                  <Grid container spacing={2}>
                    {[
                      { label: "Name", name: "name", type: "text" },
                      { label: "Email", name: "email", type: "text" },
                    ].map(({ label, name, type }) => (
                      <Grid item xs={6} sm={4} key={name}>
                        <MDBox mb={2}>
                          <TextField
                            type={type}
                            label={label}
                            fullWidth
                            name={name}
                            value={formik.values[name]}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            InputLabelProps={{ shrink: true }}
                            error={formik.touched[name] && Boolean(formik.errors[name])}
                            helperText={formik.touched[name] && formik.errors[name]}
                          />
                        </MDBox>
                      </Grid>
                    ))}
                    <Grid item xs={6} sm={4} key="phone">
                      <MDBox mb={2}>
                        <TextField
                          type="tel"
                          label="Phone"
                          fullWidth
                          name="phone"
                          value={formik.values.phone}
                          onChange={(e) => {
                            // Optionally sanitize the input to allow only numbers
                            const sanitizedValue = e.target.value.replace(/[^0-9]/g, "");
                            formik.setFieldValue("phone", sanitizedValue);
                          }}
                          onBlur={formik.handleBlur}
                          InputLabelProps={{ shrink: true }}
                          error={formik.touched.phone && Boolean(formik.errors.phone)}
                          helperText={formik.touched.phone && formik.errors.phone}
                          inputProps={{
                            pattern: "[0-9]*", // Ensure the input matches the phone number pattern (numbers only)
                          }}
                        />
                      </MDBox>
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <MDBox mb={2}>
                        <TextField
                          label="Arrival Date"
                          type="date"
                          fullWidth
                          value={formik.values.arrived_date || ""}
                          onChange={(e) => handleDateChange("arrived_date", e.target.value)}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          error={formik.touched.arrived_date && Boolean(formik.errors.arrived_date)}
                          helperText={formik.touched.arrived_date && formik.errors.arrived_date}
                        />
                      </MDBox>
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <MDBox mb={2}>
                        <TextField
                          label="Departure Date"
                          type="date"
                          fullWidth
                          value={formik.values.departure_date || ""}
                          onChange={(e) => handleDateChange("departure_date", e.target.value)}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          error={formik.touched.departure_date && Boolean(formik.errors.departure_date)}
                          helperText={formik.touched.departure_date && formik.errors.departure_date}
                        />
                      </MDBox>
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <MDBox mb={2}>
                        <TextField
                          label="Total Stay (Days)"
                          fullWidth
                          value={formik.values.total_stay || ""}
                          disabled
                          variant="outlined"
                        />
                      </MDBox>
                    </Grid>

                    {[
                      { label: "Check-In Time", name: "check_in_time", placeholder: "00:00" },
                      { label: "Check-Out Time", name: "check_out_time", placeholder: "00:00" },
                      { label: "Guests", name: "guest", type: "text" },
                      { label: "Notes", name: "notes", type: "text" },
                      { label: "Rent amount", name: "rent_amount", type: "number" },
                      { label: "Deposit amount", name: "deposit_amount", type: "number" },
                      { label: "Booking Source", name: "booking_source", type: "select" },
                      { label: "Payment method", name: "payment_method", type: "select" },
                    ].map(({ label, name, type, placeholder }) => {
                      if (name === "booking_source") {
                        return (
                          <Grid item xs={6} sm={4} key={name}>
                            <MDBox mb={2}>
                              <FormControl fullWidth variant="outlined">
                                <Autocomplete
                                  options={bookingSourceOption}
                                  onChange={(event, value) => {
                                    formik.setFieldValue(name, value?.label);
                                  }}
                                  value={formik.values[name]}
                                  InputLabelProps={{ shrink: true }}
                                  InputProps={{
                                    placeholder: type === "date" || type === "time" ? "" : undefined,
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label={label}
                                      error={formik.touched[name] && Boolean(formik.errors[name])}
                                      helperText={formik.touched[name] && formik.errors[name]}
                                    />
                                  )}
                                />
                              </FormControl>
                            </MDBox>
                          </Grid>
                        );
                      }

                      if (name === "payment_method") {
                        return (
                          <Grid item xs={6} sm={4} key={name}>
                            <MDBox mb={2}>
                              <FormControl fullWidth variant="outlined">
                                <Autocomplete
                                  options={paymentMethodsOptions}
                                  onChange={(event, value) => {
                                    formik.setFieldValue(name, value?.label);  // Set Formik value for payment_method
                                  }}
                                  value={formik.values[name]}  // Bind Formik value to Autocomplete
                                  InputLabelProps={{ shrink: true }}  // Keep label shrunk
                                  InputProps={{
                                    placeholder: type === "date" || type === "time" ? "" : undefined,  // Handle date/time placeholders if needed
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label={label}  // Use label from your mapping
                                      error={formik.touched[name] && Boolean(formik.errors[name])}  // Handle error state
                                      helperText={formik.touched[name] && formik.errors[name]}  // Display helper text if error exists
                                    />
                                  )}
                                />
                              </FormControl>
                            </MDBox>
                          </Grid>
                        );
                      }


                      return (
                        <Grid item xs={6} sm={4} key={name}>
                          <MDBox mb={2}>
                            <TextField
                              fullWidth
                              variant="outlined"
                              type={type || "text"}
                              name={name}
                              label={label}
                              placeholder={placeholder} // Add placeholder here
                              value={formik.values[name]}
                              onChange={formik.handleChange}
                              error={formik.touched[name] && Boolean(formik.errors[name])}
                              helperText={formik.touched[name] && formik.errors[name]}
                            />
                          </MDBox>
                        </Grid>
                      );
                    })}
                  </Grid>

                  <MDBox sx={{ mt: { xs: 0, sm: 4 } }} mb={1}>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item xs={6} md={6}>
                        <MDButton
                          sx={{ backgroundColor: "#0d6efd" }}
                          color="info"
                          fullWidth
                          type="submit"
                          disabled={!isFormFilled}
                        >
                          Submit
                        </MDButton>
                      </Grid>
                      <Grid item xs={6} md={6}>
                        <MDButton
                          sx={{ backgroundColor: "#0d6efd" }}
                          color="info"
                          fullWidth
                          onClick={CloseModal}
                        >
                          Close
                        </MDButton>
                      </Grid>
                    </Grid>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </MDBox>
        </form>
      </Modal>
      <ToastContainer limit={1} />
    </>
  );
};

export default ReservationModel;
