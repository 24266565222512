import React, { useState, useRef, useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import { Card, Grid, Slider, Tooltip } from "@mui/material";
import AvatarEditor from "react-avatar-editor";
import { useFormik } from "formik";
import * as Yup from "yup";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import { ToastContainer, toast } from "react-toastify";
import MDButton from "components/MDButton";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Sidenav from "examples/Sidenav";
import routes from "routes";
const index = () => {
    const [picture, setPicture] = useState({ img: null, cropperOpen: false, zoom: 1 });
    const [image, setImage] = useState(null);
    const [user, setUser] = useState([]);
    const [userId, setUserId] = useState();
    const [isChanged, setIsChanged] = useState(false);
   const navigate = useNavigate();
   const validationSchema = Yup.object().shape({
    firstname: Yup.string().required("Required"),
    lastname: Yup.string().required("Required"),
    email: Yup.string()
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 
      "Invalid email format"
    )
    .required("Email is required"),
    // contact: Yup.string()
    //   .required("Required")
    //   .matches(/^[0-9]+$/, "Contact number must be digits")
    //   .min(10, "Contact number must be at least 10 digits")
    //   .max(15, "Contact number cannot exceed 15 digits"),
  });
  const handleSave = () => {
   
    const formData = new FormData();
    if (image) {
      formData.append("userimage", image);
    }
        formData.append("firstname", formik.values.firstname);
        formData.append("lastname", formik.values.lastname);
        formData.append("email", formik.values.email);
        formData.append("contact", formik.values.contact);
        axios
          .put(`${process.env.REACT_APP_API_PATH}/updateProfile/${userId}`, formData, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then((response) => {
            const { status, message, imageurl } = response.data;
            if (status === 200) {
              toast.success(message || "Profile updated successfully!");
              userProfile();
    

              if (imageurl) {
                setUser((prev) => [
                  {
                    ...prev[0],
                    userimage: imageurl,
                    cropperOpen: false
                  },
                ]);
              }
              setPicture({ img: null, cropperOpen: false, zoom: 1 });
              handleCancel();
              setImage("");
              setIsChanged(false);
            } else {
              toast.error("Failed to update profile.");
            }
          })
          .catch((error) => {
            console.error(error);
            toast.error("An error occurred while updating the profile.");
          });
      ;
    
  };
  const formik = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      email: "",
      contact: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      handleSave(values);
    },
  });

const handleFileChange = (e) => {
  const file = e.target.files[0];
  if (file) {
    setImage(file);
    setIsChanged(true); 
  }
};
const handleInputChange = (e) => {
  formik.handleChange(e);
  setIsChanged(true); 
};

  const handleCancel = () => {
    setPicture({ img: null, cropperOpen: false, zoom: 1 });
  };

 

  const userProfile = () => {
    const token = localStorage.getItem("token");
    if (token) {
      axios
        .post(`${process.env.REACT_APP_API_PATH}/getregisterUser`, { token })
        .then((res) => {
          if (res.data.status === 200) {
            const userData = res.data.data;
            setUser(userData);
            if (userData.length > 0) {
              setUserId(userData[0].id);
              formik.setValues({
                firstname: userData[0].firstname || "",
                lastname: userData[0].lastname  || "",
                email: userData[0].email || "",
                contact: userData[0].contact || "",
                images: userData[0].images || "",
              });
              setIsChanged(false); 
            }
          }
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    userProfile();
  }, []);
  const handleOpenPasswordModal = () => {
    navigate(`/change/password`);
  };
    
   
  return (
    <>
        <DashboardLayout>
      <Sidenav color="primary" brandName="RentRoll" routes={routes} />
      
      <Grid container spacing={6} sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh", mt:{xs: 0,} }}>
              <Grid item xs={12} sm={8} md={6} lg={5}>
              <Box>
          <form onSubmit={formik.handleSubmit}>
            <Card
              sx={{
                width: {
                  xs: "90%",
                  sm: "80%",
                  md: "500px",
                  lg: "80%",
                  xl: "600px",
                    },
                   
              }}
              className="user-profile"
            >
              <MDBox
                borderRadius="lg"
                coloredShadow="info"
                mx={2}
                mt={-5}
                    p={2}
                mb={1}
                textAlign="center"
                sx={{ backgroundColor: "#1e90ff" }}
              >
                <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                  User Profile
                </MDTypography>
              </MDBox>
              <MDBox pt={4} pb={3} px={3} position="relative" textAlign="center">
                <label htmlFor="upload-button">
                  {image ? (
                    <>
                      <AvatarEditor
                        image={image}
                        width={150}
                        height={150}
                        border={10}
                        color={[73, 163, 241]}
                        rotate={0}
                        scale={picture.zoom}
                        style={{ borderRadius: "50%" }}
                        borderRadius={75}
                      />
                      
                    </>
                  ) : (
                    <>
                      {user[0]?.images ? (
                        <img
                          src={user[0]?.images}
                          alt="User Avatar"
                          width="150"
                          height="150"
                          style={{
                            borderRadius: "50%",
                            cursor: "pointer",
                          }}
                        />
                        
                        ) : (
                          <Tooltip title="Image Upload">
                          <Avatar
                            htmlFor="image-upload"
                            sx={{
                              height: 150,
                              mb: 2,
                              width: 150,
                              cursor: "pointer",
                            }}
                            />
                        </Tooltip>
                      )}
                    </>
                  )}
                </label>
                <input
                  type="file"
                  id="upload-button"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
                <Grid container spacing={2} sx={{ mt: 3 }}>
                  {[
                    { label: "First Name", name: "firstname", type: "text" },
                    { label: "Last Name", name: "lastname", type: "text" },
                    { label: "Email", name: "email", type: "text" },
                    { label: "Contact Number", name: "contact", type: "text" },
                  ].map(({ label, name, type }) => (
                    <Grid item xs={12} md={6} key={name}>
                      <MDBox mb={2}>
                        <MDInput
                          type={type}
                          label={label}
                          fullWidth
                          name={name}
                          value={formik.values[name]}
                          onChange={handleInputChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched[name] && formik.errors[name] && (
                          <div style={{ color: "red", fontSize: "17px" }}>
                            {formik.errors[name]}
                          </div>
                        )}
                      </MDBox>
                    </Grid>
                  ))}
                </Grid>
                <MDBox mt={4} mb={1}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                      <MDButton variant="gradient" color="info" fullWidth type="submit" disabled={!isChanged}>
                        Save
                      </MDButton>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <MDButton
                        variant="gradient"
                        color="info"
                        fullWidth
                        onClick={handleOpenPasswordModal}
                      >
                      Change password
                      </MDButton>
                    </Grid>
                  </Grid>
                </MDBox>
              </MDBox>
            </Card>
          </form>
        </Box>
              </Grid>
            </Grid>
      <ToastContainer limit={1}/>
    </DashboardLayout>
    </>
  )
}

export default index
