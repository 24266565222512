import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { FormControl, InputLabel } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Sidenav from "examples/Sidenav";
import routes from "routes";
import axios from "axios";
import DataTable from "examples/Tables/DataTable";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton, TextField, InputAdornment, Button, Pagination } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import Modal from '@mui/material/Modal';
import CircularProgress from "@mui/material/CircularProgress";
import { WidthFull } from "@mui/icons-material";
import { utils, writeFile } from "xlsx";
import DownloadIcon from "@mui/icons-material/Download";
import MDButton from "components/MDButton";

const Index = () => {
  const [Finance, setFinance] = useState([]);
  const [filteredFinance, setFilteredFinance] = useState([]);
  const [totalCleaningCount, setTotalCleaningCount] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);
  const currentMonth = new Date().toISOString().split("T")[0].slice(0, 7);
  const [isVisible, setIsVisible] = useState();
  const [open, setOpen] = React.useState(false);
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(true);
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [selectDate, setSelectDate] = useState(currentMonth);
  const [passwordType, setPasswordType] = useState("password");
  const [showIcon, setShowIcon] = useState(true);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const correctPassword = process.env.REACT_APP_MONTHLY_PASSWORD;
  const navigate = useNavigate()
  const handlePasswordSubmit = () => {
    if (password === correctPassword) {
      setIsAuthenticated(true);
      setIsPasswordModalOpen(false);
    } else {
      setError("Incorrect password. Please try again.");
    }
  };
  const handleCloseModal = () => {
    setIsPasswordModalOpen(false)
    navigate('/dashboard')
  }

  useEffect(() => {
    handleOpen()
  }, [])
  const FinanceReport = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${process.env.REACT_APP_API_PATH}/reportgenerator`);
      if (response.data.success && Array.isArray(response.data.data)) {
        setFinance(response.data.data);
      }
    } catch (error) {
      console.error("Failed to fetch properties!");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 300);

    return () => clearTimeout(handler);
  }, [searchTerm]);


  useEffect(() => {
    const filteredData = Finance.filter((reserve) =>
      (reserve.property_name || "").toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredFinance(filteredData);
  }, [debouncedSearchTerm, Finance]);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handledatechange = (event) => {
    setSelectDate(event.target.value);
  };

  useEffect(() => {
    FinanceReport();
  }, []);

  useEffect(() => {

    // Check if selectDate is not empty or undefined
    if (selectDate) {
      // Calculate total cleaning count for all properties combined
      const totalCleanings = filteredFinance.reduce((total, item) => {
        // Ensure item.cleaning_dates exists
        if (item.cleaning_dates && Array.isArray(item.cleaning_dates)) {
          // Filter the cleaning dates that match the selected month
          const matchingDates = item.cleaning_dates.filter(date => {
            const cleaningMonth = new Date(date).toISOString().slice(0, 7); // Extract year-month (yyyy-mm)
            return cleaningMonth === selectDate; // Match with selected month
          });

          // Add the count of cleanings for this property to the total count
          return total + matchingDates.length;
        }
        return total; // Return the current total if no cleanings are available
      }, 0); // Start with a total of 0
      setTotalCleaningCount(totalCleanings)
    } else {
      console.log('Please select a valid month!');
    }
  }, [selectDate, filteredFinance]); // Ensure to watch both selectDate and filteredFinance



  const filteredRooms = filteredFinance.filter((property) => property && property.property_id);
  const handleExport = () => {
    const headings = [
      [
        "Property Name",
        "Gross Income",
        "Occupancy(days)",
        "Occupancy(rate)",
        "Daily rent rate",
        "Other expense",
      ],
    ];

    const data = filteredRooms.map((report) => [
      report.property_name || "",
      isNaN(report.GrossIncomeByMonth[`${selectDate}`])
        ? 0
        : Number(report.GrossIncomeByMonth[`${selectDate}`]).toFixed(2) || "0.00",
      report.occupiedDaysByMonth[`${selectDate}`] || "0",
      isNaN(report.occupiedPercentageByMonth[`${selectDate}`])
        ? 0
        : Number(report.occupiedPercentageByMonth[`${selectDate}`]).toFixed(2) || "0.00",
      isNaN(report.avgDailyRentByMonth[`${selectDate}`])
        ? 0
        : Number(report.avgDailyRentByMonth[`${selectDate}`]).toFixed(2) || "0.00",
      isNaN(report.otherExpenseOfMonth[`${selectDate}`])
        ? 0
        : Number(report.otherExpenseOfMonth[`${selectDate}`]).toFixed(2),
    ]);

    const worksheetData = [...headings, ...data];

    const totalRow = [
      "Total",
      totals.totalGrossIncome,
      totals.totalOccupiedDays,
      totals.totalOccupancyRate,
      totals.totalDailyRate,
      totals.totalOtherExpenses,
      totalCleaningCount
    ];

    worksheetData.push(totalRow);

    // Create worksheet and workbook
    const wb = utils.book_new();
    const ws = utils.aoa_to_sheet(worksheetData);

    // Apply styles for headings and total row
    const headingRowIndex = 0;
    const totalRowIndex = worksheetData.length - 1;

    // Define styles
    const boldStyle = {
      font: { bold: true, size: 12 },
    };

    // Apply bold style to header row
    const range = utils.decode_range(ws["!ref"]);
    for (let col = range.s.c; col <= range.e.c; col++) {
      const cellAddress = utils.encode_cell({ r: headingRowIndex, c: col });
      if (ws[cellAddress]) {
        ws[cellAddress].s = boldStyle;
      }
    }

    // Apply bold style to the total row
    for (let col = range.s.c; col <= range.e.c; col++) {
      const cellAddress = utils.encode_cell({ r: totalRowIndex, c: col });
      if (ws[cellAddress]) {
        ws[cellAddress].s = boldStyle;
      }
    }

    // Append sheet to workbook
    utils.book_append_sheet(wb, ws, "Property Data Report");

    // Save the file
    writeFile(wb, "PropertyData_Report.xlsx");
  };
  const calculateTotals = () => {
    let totalGrossIncome = 0;
    let totalOccupiedDays = 0;
    let totalOccupancyRate = 0;
    let totalDailyRate = 0;
    let totalOtherExpenses = 0;

    filteredRooms?.forEach((property) => {
      totalGrossIncome += Number(property.GrossIncomeByMonth[selectDate]) || 0;
      totalOccupiedDays += Number(property.occupiedDaysByMonth[selectDate]) || 0;
      totalOccupancyRate += Number(property.occupiedPercentageByMonth[selectDate]) || 0;
      totalDailyRate += Number(property.avgDailyRentByMonth[selectDate]) || 0;
      totalOtherExpenses += Number(property.otherExpenseOfMonth[selectDate]) || 0;
    });

    return {
      totalGrossIncome: totalGrossIncome.toFixed(2),
      totalOccupiedDays,
      totalOccupancyRate: (totalOccupancyRate / filteredRooms.length).toFixed(2),
      totalDailyRate: (totalDailyRate / filteredRooms.length).toFixed(2),
      totalOtherExpenses: totalOtherExpenses.toFixed(2),
    };
  };

  const totals = calculateTotals();
  const columns = [
    {
      Header: () => (
        <MDTypography
          variant="h6"
          width={150}
          px={1}
          ml={2}
          style={{ WidthFull, fontWeight: "medium", color: "#000" }}
          sx={{ fontSize: { xs: '12px', sm: '16px' } }}
        >
          Properties
        </MDTypography>
      ),
      accessor: "propertyName",
    },
    {
      Header: () => (
        <MDTypography
          variant="h6"
          ml={3}
          textAlign
          style={{ fontWeight: "bold", color: "#000", width: "200px" }}
          sx={{ fontSize: { xs: '12px', sm: '16px' } }}
        >
          Gross income
        </MDTypography>
      ),
      accessor: "GrossIncome",
    },
    {
      Header: () => (
        <MDTypography
          variant="h6"
          ml={3}
          style={{ fontWeight: "bold", color: "#000" }}
          sx={{ fontSize: { xs: '12px', sm: '16px' } }}
        >
          Occupancy(days)
        </MDTypography>
      ),
      accessor: "Occupancy",
    },
    {
      Header: () => (
        <MDTypography
          variant="h6"
          ml={3}
          style={{ fontWeight: "bold", color: "#000" }}
          sx={{ fontSize: { xs: '12px', sm: '16px' } }}
        >
          Occupancy(rate)
        </MDTypography>
      ),
      accessor: "OccupancyRate",
    },
    {
      Header: () => (
        <MDTypography
          variant="h6"
          ml={3}
          style={{ fontWeight: "bold", color: "#000" }}
          sx={{ fontSize: { xs: '12px', sm: '16px' } }}
        >
          No. cleanings
        </MDTypography>
      ),
      accessor: "NoCleaning",
    },
    {
      Header: () => (
        <MDTypography
          variant="h6"
          ml={3}
          width={120}
          style={{ fontWeight: "bold", color: "#000" }}
          sx={{ fontSize: { xs: '12px', sm: '16px' } }}
        >
          Daily rent rate
        </MDTypography>
      ),
      accessor: "DailyRate",
    },
    {
      Header: () => (
        <MDTypography
          variant="h6"
          ml={2.5}
          style={{ fontWeight: "bold", color: "#000" }}
          sx={{ fontSize: { xs: '12px', sm: '16px' } }}
        >
          Cleaning expense
        </MDTypography>
      ),
      accessor: "CleaningExpense",
    },
    {
      Header: () => (
        <MDTypography
          variant="h6"
          ml={3}
          style={{ fontWeight: "bold", color: "#000" }}
          sx={{ fontSize: { xs: '12px', sm: '16px' } }}
        >
          Rent
        </MDTypography>
      ),
      accessor: "Rent",
    },
    {
      Header: () => (
        <MDTypography
          variant="h6"
          width={170}
          px={3}
          style={{ fontWeight: "bold", color: "#000" }}
          sx={{ fontSize: { xs: '12px', sm: '16px' } }}
        >
          Other(expenses)
        </MDTypography>
      ),
      accessor: "Otherexpenses",
    },
  ];

  const rows = filteredRooms?.map((property) => ({
    propertyName: (
      <MDTypography variant="caption" color="text" fontWeight="medium"
        sx={{ fontSize: { xs: "12px", sm: "14px", md: "16px" } }}

      >
        {`${property.property_name}`}
      </MDTypography>
    ),
    GrossIncome: (
      <MDTypography variant="caption" color="text" fontWeight="medium"
        sx={{ fontSize: { xs: "12px", sm: "14px", md: "16px" } }}
      >
     {`¥${Math.floor(Number(property.GrossIncomeByMonth[selectDate] ?? 0)).toLocaleString()}`}
      </MDTypography>
    ),
    Occupancy: (
      <MDTypography variant="caption" color="text" fontWeight="medium"
        sx={{ fontSize: { xs: "12px", sm: "14px", md: "16px" } }}
      >
        {Number(property.occupiedDaysByMonth[selectDate] ?? 0)}
      </MDTypography>
    ),
    OccupancyRate: (
      <MDTypography variant="caption" color="text" fontWeight="medium"
        sx={{ fontSize: { xs: "12px", sm: "14px", md: "16px" } }}
      >
        {/* {Number(property.occupiedPercentageByMonth[selectDate] ?? 0).toFixed(2)} */}
        {/* {
          `${Math.floor(property.occupiedPercentageByMonth[selectDate] || 0)}.${((property.occupiedPercentageByMonth[selectDate] || 0) % 1).toFixed(2).slice(2)}%`
        } */}
        {
          `${Math.round(property.occupiedPercentageByMonth[selectDate] || 0)}%`
        }

      </MDTypography>
    ),
    NoCleaning: (
      <MDTypography
        variant="caption"
        color="text"
        ml={10}
        fontWeight="medium"
        sx={{ fontSize: { xs: "12px", sm: "14px", md: "16px" } }}
      >
        {property.cleaning_dates?.filter(date => {
          const cleaningMonth = new Date(date).toISOString().slice(0, 7);
          return cleaningMonth === selectDate;
        }).length || 0}

      </MDTypography>
    ),
    DailyRate: (
      <MDTypography variant="caption" color="text" fontWeight="medium" sx={{ fontSize: { xs: "12px", sm: "14px", md: "16px" } }}
      >
        {`¥${isNaN(Number(property.avgDailyRentByMonth[selectDate] ?? 0).toLocaleString())
          ? "0"
          : Number(property.avgDailyRentByMonth[selectDate] ?? 0).toFixed(0)}`}

      </MDTypography>
    ),
    CleaningExpense: (
      <MDTypography
        variant="caption"
        color="text"
        ml={13}
        fontWeight="medium"
        sx={{ fontSize: { xs: "12px", sm: "14px", md: "16px" } }}
      >
        {property.guest || ""}
      </MDTypography>
    ),
    Rent: (
      <MDTypography variant="caption" color="text" fontWeight="medium" sx={{ fontSize: { xs: "12px", sm: "14px", md: "16px" } }}
      >
        {`¥${(property.rent_amount || 0).toLocaleString()}`}
      </MDTypography>
    ),
    Otherexpenses: (
      <MDTypography variant="caption" color="text" fontWeight="medium" sx={{ fontSize: { xs: "12px", sm: "14px", md: "16px" } }}
      >
        {`¥${Number(property.otherExpenseOfMonth[selectDate] ?? 0).toFixed(0)}`}
      </MDTypography>
    ),
    currency: (
      <MDTypography variant="caption" color="text" fontWeight="medium" sx={{ fontSize: { xs: "12px", sm: "14px", md: "16px" } }}
      >
        {property.currency || ""}
      </MDTypography>
    ),
    amount: (
      <MDTypography variant="caption" color="text" fontWeight="medium" sx={{ fontSize: { xs: "12px", sm: "14px", md: "16px" } }}
      >
        {/* {(property.amount || property.rent_amount || 0).toLocaleString()} */}
        {Number(property.amount || property.rent_amount || 0).toLocaleString()}

      </MDTypography>
    ),
    booking_source: (
      <MDTypography variant="caption" color="text" fontWeight="medium" sx={{ fontSize: { xs: "12px", sm: "14px", md: "16px" } }}
      >
        {property.booking_source || ""}
      </MDTypography>
    ),
    changed: (
      <MDTypography variant="caption" color="text" fontWeight="medium"
        sx={{ fontSize: { xs: "12px", sm: "14px", md: "16px" } }}
      >
        {property.changed || ""}
      </MDTypography>
    ),
    deposit_amount: (
      <MDTypography variant="caption" color="text" fontWeight="medium"
        sx={{ fontSize: { xs: "12px", sm: "14px", md: "16px" } }}
      >
        {property.deposit_amount || ""}
      </MDTypography>
    ),
    payment_method: (
      <MDTypography variant="caption" color="text" fontWeight="medium"
        sx={{ fontSize: { xs: "12px", sm: "14px", md: "16px" } }}
      >
        {property.payment_method || ""}
      </MDTypography>
    ),
    total_stay: (
      <MDTypography variant="caption" color="text" fontWeight="medium"
        sx={{ fontSize: { xs: "12px", sm: "14px", md: "16px" } }}
      >
        {property.total_stay || ""}
      </MDTypography>
    ),
  }));
  // const totalRow = {
  //   propertyName: "Total",
  //   GrossIncome: totals.totalGrossIncome,
  //   Occupancy: totals.totalOccupiedDays,
  //   OccupancyRate: totals.totalOccupancyRate,
  //   DailyRate: totals.totalDailyRate,
  //   Otherexpenses: totals.totalOtherExpenses,
  // };

  // rows.push(totalRow);

  return (
    <>
      <DashboardLayout>
        <Sidenav color="primary" brandName="RentRoll" routes={routes} />
        <Modal open={isPasswordModalOpen} aria-labelledby="password-modal">
          <Card
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              p: 3,
              boxShadow: 24,
              borderRadius: 2,
            }}
          >
            <MDTypography variant="h5" align="center" gutterBottom>
              Enter Password
            </MDTypography>
            <TextField
              fullWidth
              type="password"
              label="Password"
              variant="outlined"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              error={!!error}
              helperText={error}
              sx={{ mt: 2 }}
            />
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={6} md={6}>
                <MDButton
                  variant="contained"
                  color="info"
                  fullWidth
                  sx={{ mt: 3 }}
                  onClick={handlePasswordSubmit}
                >
                  Submit
                </MDButton>
              </Grid>
              <Grid item xs={6} md={6}>
                <MDButton
                  // variant="contained"
                  color="info"
                  fullWidth
                  xs={6} md={6}
                  sx={{ mt: 3, backgroundColor: "#0d6efd" }}
                  onClick={handleCloseModal}
                >
                  Close
                </MDButton>
              </Grid>
            </Grid>

          </Card>
        </Modal>
        {isAuthenticated &&
          <>
            <MDBox sx={{ pt: { xs: 6, sm: 5 } }} pb={3}>
              <Grid container spacing={6} mb={5}>
                <Grid item xs={12}>
                  <Card>
                    <MDBox
                      mx={2}
                      mt={-3}
                      py={3}
                      px={2}
                      variant="gradient"
                      sx={{
                        backgroundColor: "#1e90ff", display: { sm: "flex" },
                        justifyContent: { xs: "center", sm: "space-between" },
                      }}
                      borderRadius="lg"
                      coloredShadow="info"
                      // display="flex"
                      // justifyContent="space-between"
                      alignItems="center"
                    >
                      <MDTypography variant="h5" color="white" style={{ float: "left" }} sx={{ pb: { xs: 2, sm: 0 } }}>
                        Monthly Report
                      </MDTypography>
                      <MDBox mt={1} mb={1} style={{ float: "right", display: "flex", gap: "10px" }} >
                        <TextField
                          placeholder="Search"
                          size="small"
                          sx={{
                            "& .MuiInputBase-root": {
                              backgroundColor: "#ffffff",
                              color: "#000000",
                              border: "none",
                              padding: "3px 8px",
                            },
                            "& .MuiInputLabel-root": {
                              display: "none",
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                              border: "none",
                            },
                            "& .MuiInputBase-input": {
                              padding: "8px",
                            },
                            display: { xs: 'none', sm: 'block' }
                          }}
                          inputProps={{ "aria-label": "Search" }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start" sx={{ fontSize: "18px" }}>
                                <SearchIcon color="black" fontWeight="bold" />
                              </InputAdornment>
                            ),
                          }}
                          onChange={handleSearch}
                          value={searchTerm}
                        />

                        <FormControl variant="outlined" style={{ minWidth: 120 }}>
                          <InputLabel id="start-Month" style={{ color: "#ffff", }}
                            sx={{ marginTop: { xs: "-20px", sm: "-35px" } }}>
                            Select Month
                          </InputLabel>
                          <TextField
                            id="start-month"
                            type="month"
                            variant="outlined"
                            onChange={handledatechange}
                            value={selectDate}
                            sx={{
                              "& .MuiInputBase-root": {
                                backgroundColor: "#ffffff",
                                color: "#000000",
                                border: "none",
                                padding: "4px 0px",

                              },
                              "& .MuiInputLabel-root": {
                                display: "none",
                              },
                              "& .MuiOutlinedInput-notchedOutline": {
                                display: "none",
                              },
                              "& .MuiInputBase-input": {
                                padding: "6px",
                              },
                              marginTop: {
                                xs: "10px",
                                sm: "0px"
                              },
                              padding: {
                                xs: "0",
                                // sm: "4px 10px"
                              }
                            }}
                            inputProps={{
                              "aria-label": "start-month",
                              min: "2014-01",
                              max: "2030-12",
                            }}
                          />
                        </FormControl>
                        <Button
                          style={{
                            color: "black",
                            border: "1px solid white",
                            background: "white",
                          }}
                          onClick={handleExport}
                        >
                          Download Report
                          <MDBox sx={{ display: { xs: 'none', sm: 'block' } }}>
                            <DownloadIcon />
                          </MDBox>
                        </Button>
                      </MDBox>
                    </MDBox>
                    {loading ? (
                      <MDTypography
                        variant="caption"
                        color="text"
                        fontWeight="medium"
                        align="center"
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100vh",
                        }}
                      >
                        <CircularProgress disableShrink sx={{ color: "#1e90FF" }} />
                      </MDTypography>
                    ) : (
                      <MDBox pt={3}>
                        {filteredRooms.length > 0 ? (
                          <>
                            <DataTable
                              table={{ columns, rows }}
                              isSorted={false}
                              entriesPerPage={{ defaultValue: 15, entries: [10, 20, 30, 40, 50, 60] }}
                              showTotalEntries={false}
                              noEndBorder
                            />

                          </>
                        ) : (
                          <MDTypography variant="h6" align="center" m={2} sx={{ color: "red" }}>
                            No Data Found .
                          </MDTypography>
                        )}
                      </MDBox>
                    )}
                  </Card>
                </Grid>
              </Grid>
              <Card>
                {filteredRooms.length > 0 ? (
                  <>
                    <MDBox
                      mt={2}

                      display="flex"
                      justifyContent="space-between"
                      bgcolor="#f5f5f5"
                      p={2}
                      borderRadius="8px"
                      sx={{
                        maxHeight: "300px",
                        overflowX: "auto",
                        overflowY: "auto",
                      }}
                    >
                      <MDTypography variant="h6" color="text" mt={2.7} style={{ fontWeight: "bold" }}
                        sx={{ fontSize: { xs: '12px', sm: '16px' } }}
                      >
                        Total
                      </MDTypography>
                      <MDTypography variant="h6" align="center" color="text" style={{ fontWeight: "bold" }}
                        sx={{ fontSize: { xs: '12px', sm: '16px' } }}>
                        <MDTypography variant="h6" width={170} px={3} style={{ fontWeight: "bold", color: "text" }} sx={{ fontSize: { xs: '12px', sm: '15px' } }}>Gross Income </MDTypography>
                        {/* {`¥${Number(totals.totalGrossIncome).toFixed(0).toLocaleString()}`} */}
                        {`¥${Math.floor(Number(totals.totalGrossIncome)).toLocaleString()}`}
                      </MDTypography>
                      <MDTypography variant="h6" align="center" color="text" style={{ fontWeight: "bold" }} sx={{ fontSize: { xs: '12px', sm: '16px' } }}>
                        <MDTypography variant="h6" width={170} px={3} style={{ fontWeight: "bold", color: "text" }} sx={{ fontSize: { xs: '12px', sm: '15px' } }}>Occupancy(days) </MDTypography>
                        {totals.totalOccupiedDays}
                      </MDTypography>
                      <MDTypography variant="h6" align="center" color="text" style={{ fontWeight: "bold" }} sx={{ fontSize: { xs: '12px', sm: '16px' } }}>
                        <MDTypography variant="h6" width={170} px={3} style={{ fontWeight: "bold", color: "text" }} sx={{ fontSize: { xs: '12px', sm: '15px' } }}>Occupancy(rate) </MDTypography>

                        {/* {totals.totalOccupancyRate} */}
                      {/* {`${Math.round(totals.totalOccupancyRate)}.${(totals.totalOccupancyRate % 1)}%`}, */}
                      {`${Math.round(totals.totalOccupancyRate)}%`}

                      </MDTypography>
                      <MDTypography variant="h6" align="center" color="text" style={{ fontWeight: "bold" }}>
                        <MDTypography variant="h6" width={170} px={3} style={{ fontWeight: "bold", color: "text" }} sx={{ fontSize: { xs: '12px', sm: '15px' } }}>No.of cleaning </MDTypography>

                        {totalCleaningCount || 0}
                      </MDTypography>
                      <MDTypography variant="h6" align="center" color="text" style={{ fontWeight: "bold" }} sx={{ fontSize: { xs: '12px', sm: '16px' } }}>
                        <MDTypography variant="h6" width={170} px={3} style={{ fontWeight: "bold", color: "text" }} sx={{ fontSize: { xs: '12px', sm: '15px' } }}>Daily rent </MDTypography>

                        {/* {`¥${Number(totals.totalDailyRate).toFixed(0)}`} */}
                        {`¥${Math.round(Number(totals.totalDailyRate)).toLocaleString()}`}
                      </MDTypography>
                      <MDTypography variant="h6" align="center" color="text" style={{ fontWeight: "bold" }} sx={{ fontSize: { xs: '12px', sm: '16px' } }}>
                        <MDTypography variant="h6" width={170} px={3} style={{ fontWeight: "bold", color: "text" }} sx={{ fontSize: { xs: '12px', sm: '15px' } }}>Cleaning expenses</MDTypography>
                        {0 || 0}
                      </MDTypography>
                      <MDTypography variant="h6" align="center" color="text" style={{ fontWeight: "bold" }} sx={{ fontSize: { xs: '12px', sm: '16px' } }}>
                        <MDTypography variant="h6" width={170} px={3} style={{ fontWeight: "bold", color: "text" }} sx={{ fontSize: { xs: '12px', sm: '15px' } }}>Rent </MDTypography>

                        {0 || 0}
                      </MDTypography>
                      <MDTypography variant="h6" align="center" sx={{ fontSize: { xs: '12px', sm: '16px' } }}>
                        <MDTypography variant="h6" width={170} px={3} style={{ fontWeight: "bold", color: "text" }} sx={{ fontSize: { xs: '12px', sm: '15px' } }}>Other expenses </MDTypography>
                        {`¥${Number(totals.totalOtherExpenses).toFixed(0)}`}
                      </MDTypography>
                    </MDBox>

                  </>
                ) : (
                  <MDTypography variant="h6" align="center" m={2} sx={{ color: "red" }}>
                    No Data Found.
                  </MDTypography>
                )}
              </Card>
            </MDBox>
          </>
        }

      </DashboardLayout>
    </>
  );
};

export default Index;
