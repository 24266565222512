import * as React from "react";
import Modal from "@mui/material/Modal";
import { useFormik } from "formik";
import * as Yup from "yup";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import {
  Button,
  Card,
  Grid,
  TextField,
  FormControl,
  Autocomplete,
  Typography,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { useEffect, useState } from "react";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import { useParams } from "react-router";
dayjs.extend(timezone);
const style = {
  position: "absolute",
  top: { xs: 400, sm: 400, md: 400, xl: 400 },
  width: 600,
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  pt: 2,
  pb: 3,
};

const ExpensesModel = ({ isVisible, Close, fetchExpenses, Selectedexpense, handleExpenseById }) => {
  const [file, setFile] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);
  const [fileName, setFileName] = useState("");
  const [isPdfFile, setIsPdfFile] = useState("");
  const [properties, setProperties] = useState([]);
  const [IsSelectProperty, setSelectProperty] = useState(null);
  const [PropertyId, setPropertyId] = useState(null);
  const { property_id } = useParams();

  useEffect(() => {
    const propertyFromExpense = Selectedexpense?.property_id || IsSelectProperty;
    setPropertyId(propertyFromExpense);
  }, [Selectedexpense?.property_id, IsSelectProperty]);

  const validationSchema = Yup.object({
    // property: Yup.object()
    //   .shape({
    //     property_id: Yup.mixed().required("Property is required"),
    //     property_name: Yup.mixed().required("Property name is required"),
    //   })
    //   .nullable()
    //   .required("Property is required"),
  });
  const onSubmit = async (values) => {
    try {
      const formData = new FormData();

      const startDate = new Date(values.startdate);
      const isValidStartDate = !isNaN(startDate.getTime());

      const endDate = new Date(values.end_date);
      const isValidEndDate = !isNaN(endDate.getTime());

      formData.append("expense", values.expense);
      formData.append("date", values.date || null);
      formData.append("startdate", isValidStartDate ? values.startdate : null);
      formData.append("end_date", isValidEndDate ? values.end_date : null);
      formData.append("amount", values.amount || null);
      formData.append("description", values.description || null);
      formData.append("property_id", values.property_id || PropertyId);
      if (file) {
        formData.append("invoice", file);
      } else if (Selectedexpense && Selectedexpense.files) {
        formData.append("invoice", Selectedexpense.files);
      }

      let response;
      if (Selectedexpense) {
        response = await axios.put(
          `${process.env.REACT_APP_API_PATH}/update-expense-data/expense-id/${Selectedexpense.expense_id} `,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      } else {
        response = await axios.post(
          `${process.env.REACT_APP_API_PATH}/addexpense/property_id/${
            (IsSelectProperty, property_id)
          } `,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      }

      if (response && response.status === 200) {
        toast.success("Expenses added successfully!");
        formik.resetForm();
        setFile("");
        setFileName("");
        CloseModal();
        handleExpenseById(formData);
      }
    } catch (error) {
      console.error("Failed to add/update room data. Please try again.", error);
    }
  };
  const formik = useFormik({
    initialValues: {
      expense: "",
      date: "",
      startdate: "",
      end_date: "",
      amount: "",
      description: "",
      property: PropertyId,
    },
    validationSchema,
    onSubmit: async (values) => {
      onSubmit(values);
      setFileName("");
      setFile("");
      setFileUrl("");
      formik.resetForm();
    },
  });
  useEffect(() => {
    if (Selectedexpense) {
      formik.setValues({
        expense: Selectedexpense?.expense || "",
        date: dayjs(Selectedexpense.date).format("YYYY-MM-DD") || "",
        startdate: dayjs(Selectedexpense.startdate).format("YYYY-MM-DD") || "",
        end_date: dayjs(Selectedexpense.end_date).format("YYYY-MM-DD") || "",
        description: Selectedexpense?.description || "",
        amount: Selectedexpense?.expense_amount,
        expense: Selectedexpense?.title || "",
        property: {
          property_id: Selectedexpense?.property_id,
          property_name: Selectedexpense?.property_name,
        },
        files: Selectedexpense?.files || "",
      });
      setFileName(Selectedexpense?.files || "");
    } else {
      setFileName("");
      formik.resetForm();
      setFile("");
    }
  }, [Selectedexpense]);
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const url = URL.createObjectURL(selectedFile);
      const name = url.split("/")[3];
      setIsPdfFile(name);
      setFileUrl(url || "");
      setFile(selectedFile);
    }
  };

  const openFileInNewTab = () => {
    const fileToOpen = fileUrl || fileName;
    if (fileToOpen) {
      window.open(fileToOpen, "_blank");
    } else {
      toast.error("No file available to open!");
    }
  };
  const CloseModal = () => {
    setFileName("");
    setFile("");
    setFileUrl("");
    setPropertyId("");
    formik.resetForm();
    Close();
  };

  const addProperty = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_PATH}/getproperties`);
      if (response.data.success && Array.isArray(response.data.data)) {
        setProperties(response.data.data);
      }
    } catch (error) {
      console.error("Failed to fetch properties!");
    }
  };

  useEffect(() => {
    addProperty();
  }, []);
  const splitFile = fileName.split("/uploads/").pop();
  const isFormFilled = Object.values(formik.values).some(
    (value) => typeof value === "string" && value.trim() !== ""
  );
  return (
    <>
      <Modal
        open={isVisible}
        aria-labelledby="parent-modal-expense"
        aria-describedby="parent-modal-description"
      >
        <form onSubmit={formik.handleSubmit}>
          <Card
            sx={{
              ...style,
              width: {
                xs: "90%",
                sm: "80%",
                md: "500px",
                lg: "60%",
                xl: "600px",
              },
            }}
          >
            <MDBox
              borderRadius="lg"
              coloredShadow="info"
              mx={2}
              mt={-3}
              p={2}
              mb={1}
              textAlign="center"
              sx={{ backgroundColor: "#1e90ff" }}
            >
              <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                {Selectedexpense ? "Edit Expense" : "Add Expense"}
              </MDTypography>
            </MDBox>
            <MDBox pt={4} pb={3} px={3}>
              <Grid container spacing={2}>
                {/* Expense and Expense Amount in One Row */}
                <Grid item xs={12} sm={6}>
                  <MDBox mb={1}>
                    <TextField
                      label="Expense"
                      type="text"
                      fullWidth
                      name="expense"
                      value={formik.values.expense}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      InputLabelProps={{ shrink: true }}
                      multiline
                      rows={1.4}
                      error={formik.touched.expense && Boolean(formik.errors.expense)}
                      helperText={formik.touched.expense && formik.errors.expense}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MDBox mb={2}>
                    <TextField
                      label="Expense Amount"
                      type="text"
                      fullWidth
                      name="amount"
                      value={formik.values.amount}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      InputLabelProps={{ shrink: true }}
                      multiline
                      rows={1}
                      error={formik.touched.amount && Boolean(formik.errors.amount)}
                      helperText={formik.touched.amount && formik.errors.amount}
                    />
                  </MDBox>
                </Grid>

                {/* Start Date and End Date in One Row */}
                <Grid item xs={12} sm={6}>
                  <MDBox mb={2}>
                    <TextField
                      label="Start Date"
                      type="date"
                      fullWidth
                      name="startdate"
                      value={formik.values.startdate}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      InputLabelProps={{ shrink: true }}
                      error={formik.touched.startdate && Boolean(formik.errors.startdate)}
                      helperText={formik.touched.startdate && formik.errors.startdate}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MDBox mb={2}>
                    <TextField
                      label="End Date"
                      type="date"
                      fullWidth
                      name="end_date"
                      value={formik.values.end_date}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      InputLabelProps={{ shrink: true }}
                      error={formik.touched.end_date && Boolean(formik.errors.end_date)}
                      helperText={formik.touched.end_date && formik.errors.end_date}
                    />
                  </MDBox>
                </Grid>

                {/* Processing Date and Upload Invoice in One Row */}
                <Grid item xs={12} sm={6}>
                  <MDBox mb={2}>
                    <TextField
                      label="Processing Date"
                      type="date"
                      fullWidth
                      name="date"
                      value={formik.values.date}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      InputLabelProps={{ shrink: true }}
                      error={formik.touched.date && Boolean(formik.errors.date)}
                      helperText={formik.touched.date && formik.errors.date}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MDBox display="flex" alignItems="center" mb={2} width={283}>
                    <FormControl fullWidth>
                      <Button
                        component="label"
                        variant="outlined"
                        startIcon={<UploadFileIcon style={{ color: "#7b809a" }} />}
                        sx={{
                          marginRight: "1rem",
                          "&:hover": {
                            borderColor: "#0d6efd",
                          },
                        }}
                      >
                        <span style={{ color: "#7b809a" }}>Upload Invoice</span>
                        <input
                          type="file"
                          accept="application/pdf,.pdf, .jpg, .jpeg, .png, .doc, .docx, .txt"
                          hidden
                          onChange={handleFileChange}
                        />
                      </Button>
                      <MDTypography
                        variant="caption"
                        onClick={openFileInNewTab}
                        sx={{ marginTop: 1, cursor: "pointer", fontSize: "14px", color: "#007bff" }}
                      >
                        {fileUrl ? (
                          <Typography variant="caption">{fileUrl}</Typography>
                        ) : splitFile ? (
                          <Typography variant="caption">{splitFile}</Typography>
                        ) : null}
                      </MDTypography>
                    </FormControl>
                  </MDBox>
                </Grid>

                {/* Expense Description in One Row */}
                <Grid item xs={12}>
                  <MDBox mb={2}>
                    <TextField
                      label="Expenses description"
                      type="text"
                      fullWidth
                      name="description"
                      value={formik.values.description}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      InputLabelProps={{ shrink: true }}
                      multiline
                      rows={4}
                      error={formik.touched.description && Boolean(formik.errors.description)}
                      helperText={formik.touched.description && formik.errors.description}
                    />
                  </MDBox>
                </Grid>
              </Grid>

              {/* Buttons */}
              <MDBox mt={4} mb={1}>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6}>
                    <MDButton
                      sx={{ backgroundColor: "#0d6efd" }}
                      color="info"
                      fullWidth
                      type="submit"
                      disabled={!isFormFilled}
                    >
                      Submit
                    </MDButton>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <MDButton
                      sx={{ backgroundColor: "#0d6efd" }}
                      color="info"
                      fullWidth
                      onClick={CloseModal}
                    >
                      Close
                    </MDButton>
                  </Grid>
                </Grid>
              </MDBox>
            </MDBox>
          </Card>
        </form>
      </Modal>
      <ToastContainer limit={1} />
    </>
  );
};

export default ExpensesModel;
