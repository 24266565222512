import React, { useState, useEffect } from "react";
import axios from "axios";
import Tooltip from "@mui/material/Tooltip";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { IconButton, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useGetCleaningDataQuery } from "slice/apiCleaningSlice";
import { FormControl, InputLabel } from "@mui/material";
import ApartmentIcon from "@mui/icons-material/Apartment";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import MDBox from "components/MDBox";
import logoImg from "../../assets/images/test-removebg-preview.png"
import { use } from "react";

const PublicCleaning = () => {
  const [cleaningData, setCleaningData] = useState([]);
  const [filteredCleaning, setFilteredCleaning] = useState([]);
  const [dropDownOptions, setDropDownOptions] = useState([]);
  const [dropDownProperty, setDropDownProperty] = useState("");
  const currentMonth = new Date().toISOString().split("T")[0].slice(0, 7);
  const [loading, setLoading] = useState(true);
  const Token = localStorage.getItem("token");
  const [selectDate, setSelectDate] = useState(currentMonth);
  const navigate = useNavigate();
  const { data, isFetching, isError } = useGetCleaningDataQuery()

  useEffect(() => {
    if (isFetching) {
      setLoading(true);
    } else if (isError) {
      setLoading(false);
    } else if (data?.success && Array.isArray(data.data)) {
      setCleaningData(data.data);
      setLoading(false);
    }
  }, [data, isFetching, isError]);

  const handleBack = (e) => {
    e.preventDefault();
    navigate(-1);
  }
  const handledatechange = (event) => {
    setSelectDate(event.target.value);
  };

  useEffect(() => {
    const filteredData = cleaningData.filter(item =>
      item.cleaning_date && item.cleaning_date.slice(0, 7) === selectDate &&
      item.property_name.toLowerCase().includes(dropDownProperty.toLowerCase())
    );
    setFilteredCleaning(filteredData);
  }, [selectDate, dropDownProperty, cleaningData]);

  useEffect(() => {
    const options = cleaningData.map((property) => property.property_name); // Extract property names
    setDropDownOptions(options);
  }, [cleaningData])

  // Define cellStyle for reuse


  const rowStyle = {
    borderBottom: "1px solid #ddd",
  };

  const cellStyle = {
    padding: "10px 8px",
    paddingLeft: "10px",
    textAlign: "left",
    fontSize: "14px",
  };

  return (
    <>
      <header id="header" style={{ position: "relative", zIndex: 1000 }} >
        <nav
          className="navbar navbar-expand-lg navbar-light"
          // style={{ backgroundColor: "#ffffff", boxShadow: "0 2px 4px rgba(0,0,0,0.1)" }}
          variant="gradient"
          // sx={{ backgroundColor: "#1e90ff" }}
          // style={{ backgroundColor: "#1e90ff" }}
        >
          <div
            className="navbar-collapse d-flex align-items-center"
            style={{ paddingLeft: "20px" }}
          >
            <h3 className="logo-text text-dark">
            <img src={logoImg} style={{width: '100px', height: '100px', mixBlendMode: "darken", }} />
              Tokyo-Furnished
            </h3>

          </div>
          <div className="d-flex align-items-center justify-content-end">
            <MDBox mr={2} sx={{ width: { xs: 120, sm: 200 } }}>
              <Autocomplete
                options={dropDownOptions}
                getOptionLabel={(option) => option}
                onChange={(event, value) => setDropDownProperty(value || "")}
                value={dropDownProperty}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Search"
                    sx={{
                      background: "white",
                      color: "black",
                      border: "none",
                      margin: "0px",
                      borderRadius: "5px",
                      ".MuiOutlinedInput-root": {
                        padding: "5px",
                      },
                      ".MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      ".MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                    }}
                  />
                )}
              />
            </MDBox>
            <MDBox mr={2}>
              <FormControl variant="outlined" style={{ minWidth: 120 }}>
                <InputLabel id="start-Month" style={{ color: "#000", }}
                  sx={{ marginTop: { xs: "-20px", sm: "-35px" } }}>
                  Select Month
                </InputLabel>
                <TextField
                  id="start-month"
                  type="month"
                  variant="outlined"
                  onChange={handledatechange}
                  value={selectDate}
                  sx={{
                    "& .MuiInputBase-root": {
                      backgroundColor: "#ffffff",
                      color: "#000000",
                      border: "none",
                      padding: "4px 0px",

                    },
                    "& .MuiInputLabel-root": {
                      display: "none",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      display: "none",
                    },
                    "& .MuiInputBase-input": {
                      padding: "6px",
                    },
                    marginTop: {
                      xs: "10px",
                      sm: "0px"
                    },
                    padding: {
                      xs: "0",
                      // sm: "4px 10px"
                    }
                  }}
                  inputProps={{
                    "aria-label": "start-month",
                    min: "2014-01",
                    max: "2030-12",
                  }}
                />
              </FormControl>
            </MDBox>
          </div>
        </nav>
      </header>
      <div style={{ backgroundColor: "white" }}>

        <div
          style={{
            overflowX: "auto",
            position: "relative",
            background: "white",
            maxHeight: "calc(100vh - 80px)",
          }}
        >
          <table
            style={{
              marginTop: "20px",
              width: "100%",
              borderCollapse: "collapse", // Ensure rows look connected
              backgroundColor: "white",
            }}
          >
            <thead
              style={{
                position: "sticky",
                top: 0,
                backgroundColor: "#fff",
                zIndex: 1,
                padding: "10px",
              }}
            >
              <tr style={{ fontSize: "20px", marginBottom: "10px" }}>
                <th style={{ ...cellStyle, minWidth: "120px", whiteSpace: "nowrap", fontSize: "16px" }}>
                  Cleaning Date
                </th>
                <th style={{ ...cellStyle, minWidth: "100px", whiteSpace: "nowrap", fontSize: "16px" }}>Properties</th>
                <th style={{ ...cellStyle, minWidth: "120px", whiteSpace: "nowrap", fontSize: "16px" }}>Name</th>
                <th style={{ ...cellStyle, minWidth: "150px", whiteSpace: "nowrap", fontSize: "16px" }}>Email</th>
                <th style={{ ...cellStyle, minWidth: "120px", whiteSpace: "nowrap", fontSize: "16px" }}>
                  Move Out Date
                </th>
                <th style={{ ...cellStyle, minWidth: "120px", whiteSpace: "nowrap", fontSize: "16px" }}>
                  Check In Time
                </th>
                <th style={{ ...cellStyle, minWidth: "120px", whiteSpace: "nowrap", fontSize: "16px" }}>
                  Check Out Time
                </th>
                <th style={{ ...cellStyle, minWidth: "100px", whiteSpace: "nowrap", fontSize: "16px" }}>Guests</th>
                <th style={{ ...cellStyle, minWidth: "120px", whiteSpace: "nowrap", fontSize: "16px" }}>Cleaner</th>
                <th style={{ ...cellStyle, minWidth: "150px", whiteSpace: "nowrap", fontSize: "16px" }}>
                  Cleaner Email
                </th>
                <th style={{ ...cellStyle, minWidth: "100px", whiteSpace: "nowrap", fontSize: "16px" }}>Todo</th>
                <th style={{ ...cellStyle, minWidth: "150px", whiteSpace: "nowrap", fontSize: "16px" }}>Note</th>
                <th style={{ ...cellStyle, minWidth: "120px", whiteSpace: "nowrap", fontSize: "16px" }}>Origin</th>
                <th style={{ ...cellStyle, minWidth: "120px", whiteSpace: "nowrap", fontSize: "16px" }}>Maintenance</th>
              </tr>
            </thead>

            <tbody style={{ fontSize: "20px", color: "GrayText", fontWeight: "bold", overflowY: "auto" }}>
              {filteredCleaning.length === 0 ? (
                <tr>
                  <td
                    colSpan="14"
                    style={{
                      textAlign: "center",
                      padding: "10px",
                      backgroundColor: "#f5f5f5",
                    }}
                  >
                    <span
                      style={{
                        color: "red",
                        fontWeight: "bold",
                        fontSize: "14px",
                      }}
                    >
                      No data found
                    </span>
                  </td>
                </tr>
              ) : (
                filteredCleaning.map((row, index) => (
                  <tr key={index} style={rowStyle}>
                    <td style={cellStyle}>
                      <div style={{ marginTop: "10px" }}>
                        {new Date(row.cleaning_date).toLocaleDateString("en-GB")}
                      </div>
                    </td>
                    <td style={cellStyle}>{row.property_name}</td>
                    <td style={cellStyle}>
                      {row.reservation_name.length > 15 ? (
                        <Tooltip title={row.reservation_name}>
                          <span style={{ cursor: "pointer" }}>
                            {row.reservation_name.substring(0, 15)}...
                          </span>
                        </Tooltip>
                      ) : (
                        row.reservation_name
                      )}
                    </td>
                    <td style={cellStyle}>{row.reservation_email}</td>
                    <td style={cellStyle}>
                      {new Date(row.departure_date).toLocaleDateString("en-GB")}
                    </td>
                    <td style={cellStyle}>{row.check_in_time}</td>
                    <td style={cellStyle}>{row.check_out_time}</td>
                    <td style={cellStyle}>{row.guests}</td>
                    <td style={cellStyle}>{row.cleaner_name}</td>
                    <td style={cellStyle}>{row.cleaner_email}</td>
                    <td style={cellStyle}>{row.todo}</td>
                    <td style={cellStyle}>
                      {row.notes && row.notes.length > 10 ? (
                        <Tooltip title={row.notes}>
                          <span style={{ cursor: "pointer" }}>{row.notes.substring(0, 10)}...</span>
                        </Tooltip>
                      ) : (
                        row.notes || ""
                      )}
                    </td>
                    <td style={cellStyle}>{row.booking_source}</td>
                    <td style={cellStyle}>{row.maintenance}</td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default PublicCleaning;
