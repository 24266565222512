import { fetchBaseQuery , createApi } from "@reduxjs/toolkit/query/react"

export const apiDropDownSlice = createApi({
    reducerPath : "apiDropDown",
    baseQuery : fetchBaseQuery({
        baseUrl : process.env.REACT_APP_API_PATH
    }),
    endpoints : (builder)=>({
        getDropDown : builder.query({
            query : ()=> `/getdrop-down`
        }),
    })
})
export const {useGetDropDownQuery} = apiDropDownSlice